import { FC } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Drawer } from "@mui/material";
import { useIsXlDesktopView } from "../../hooks/useWindowSize";
import { Cryptocurrency } from "../../types/cryptocurrencies";
import Section from "../Section";
import EditCryptocurrencyForm from "./EditCryptocurrencyForm";
import { useStyles } from "./styles";

interface Props {
  data?: Cryptocurrency;
  onClose: () => void;
}

const EditCryptocurrencyPanel: FC<Props> = ({ data, onClose }) => {
  const classes = useStyles();
  const inXlDesktopView = useIsXlDesktopView();

  const sectionTitle = data?.crypto_id
    ? "Cryptocurrency Details"
    : "New Cryptocurrency Details";

  if (inXlDesktopView) {
    return (
      <Box className={classes.editBox} pl={2}>
        <Box className={classes.closeContainer}>
          <Box className={classes.closeIcon} onClick={onClose}>
            <CloseIcon />
          </Box>
        </Box>
        <Section title={sectionTitle}>
          <EditCryptocurrencyForm data={data} onClose={onClose} />
        </Section>
      </Box>
    );
  }

  return (
    <Drawer anchor="right" open onClose={onClose}>
      <Box className={classes.editBox} p={2}>
        <Section title={sectionTitle}>
          <EditCryptocurrencyForm data={data} onClose={onClose} />
        </Section>
      </Box>
    </Drawer>
  );
};

export default EditCryptocurrencyPanel;
