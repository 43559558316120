import { red } from "@mui/material/colors";
import { createTheme, Theme } from "@mui/material/styles";
import { CSSProperties } from "@mui/styles";

type StyleKey = "page" | "pageHeader" | "pageBody";

export const pageStyles = (
  theme: Theme
): { [key in StyleKey]: CSSProperties } => ({
  page: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "1rem",
    background: "#F6F6F6",
  },
  pageHeader: {
    fontSize: 18,
    fontWeight: 600,
    textTransform: "uppercase",
    padding: "0.75rem 1.5rem",
    marginBottom: "0.75rem",
    background: "#88B5FF",
    borderRadius: 6,
    overflow: "hidden",
    color: "#fff",
  },
  pageBody: {
    flex: 1,
    overflow: "auto",
  },
});

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "#556cd6",
    },
    secondary: {
      main: "#19857b",
    },
    error: {
      main: red.A400,
    },
  },
});

export default theme;
