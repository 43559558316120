import { ChangeEvent, useEffect, useMemo, useState } from "react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Box, Button, SelectChangeEvent, Switch } from "@mui/material";
import EditNewsPanel from "../../components/EditNewsPanel";
import SearchInput from "../../components/SearchInput";
import Section from "../../components/Section";
import Select from "../../components/Select";
import Table, { Column } from "../../components/Table";
import {
  useFetchNewsCategorizationQuery,
  useFetchNewsQuery,
  useUpdateNewsMutation,
} from "../../redux/queries/news";
import { News } from "../../types";
import { formatDateTime } from "../../utils/datetime";
import { useStyles } from "./styles";

const NewsPage = () => {
  const classes = useStyles();

  const { data, isFetching } = useFetchNewsQuery({});
  const { data: categorizations } = useFetchNewsCategorizationQuery({});

  const [updateNews, { isLoading: isLoadingStatus }] = useUpdateNewsMutation();

  const [query, setQuery] = useState<string>("");
  const [page, setPage] = useState<number>(-1);
  const [selected, setSelected] = useState<number>();
  const [categorizationId, setCategorizationId] = useState<number>();

  const [createPanelVisible, setCreatePanelVisible] = useState<boolean>(false);

  const openCreatePanel = () => {
    setSelected(undefined);
    setCreatePanelVisible(true);
  };
  const closeCreatePanel = () => setCreatePanelVisible(false);

  const selectedNews = useMemo(
    () => data?.find((item) => item.id_m_web_news === selected),
    [data, selected]
  );

  const handleChangeCategorization = (event: SelectChangeEvent<any>) => {
    setCategorizationId(event.target.value);
  };

  const handleChangeQuery = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  const handleChangeStatus = (e: ChangeEvent<HTMLInputElement>, data: News) => {
    updateNews({
      id_m_web_news: data.id_m_web_news,
      status: data.status === "A" ? "I" : "A",
    });
  };

  const handleSelect = (val: number) => {
    closeCreatePanel();
    setSelected(val);
  };
  const resetSelected = () => setSelected(undefined);

  const TableColumns: Column[] = [
    {
      key: "title",
      label: "Title",
      sortable: true,
      colSpan: 2,
      width: "40%",
      renderCell: (data) => (
        <Box sx={{ color: "primary.main", fontWeight: "bold" }}>
          {data.title}
        </Box>
      ),
    },
    {
      key: "createdate",
      label: "Date",
      sortable: true,
      renderCell: (data) => formatDateTime(data.createdate),
    },
    {
      key: "categorization",
      label: "Type",
      sortable: true,
      renderCell: (data) => data.categorization.name,
    },
    {
      key: "option",
      label: "Options",
      sortable: false,
      renderCell: (data) => (
        <Box
          sx={{ cursor: "pointer" }}
          onClick={() => handleSelect(data.id_m_web_news)}
        >
          <EditOutlinedIcon />
        </Box>
      ),
    },
    {
      key: "activation",
      label: "Activation",
      sortable: false,
      renderCell: (data) => (
        <Switch
          checked={data.status === "A"}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleChangeStatus(e, data as News)
          }
        />
      ),
    },
  ];

  const visibleKeys = TableColumns.map((col) => col.key);

  const tableData = useMemo(() => {
    if (!data) return [];

    return data.filter((item) => {
      if (
        categorizationId &&
        item.categorization?.id_m_web_news_categorization !== categorizationId
      ) {
        return false;
      }

      if (query?.length > 2) {
        return visibleKeys.some(
          (k) =>
            (item as any)[k] &&
            ((item as any)[k] as any)
              .toString()
              .toLowerCase()
              .includes(query.toLowerCase())
        );
      }
      return true;
    });
  }, [categorizationId, data, query, visibleKeys]);

  const categorizationOptions = useMemo(
    () =>
      categorizations?.map((item) => ({
        label: item.name,
        value: item.categorization_id,
      })),
    [categorizations]
  );

  useEffect(() => {
    setPage(0);
  }, [categorizationId]);

  useEffect(() => {
    // Reset table page to 0 when search query gets updated
    if (query.length >= 3) {
      setPage(0);
    }
  }, [query]);

  return (
    <Box className={classes.page}>
      <Box className={classes.pageHeader}>News</Box>
      <Box className={classes.container}>
        <Box className={classes.tableBox}>
          <Section title="LATEST NEWS LIST">
            <Box className={classes.headerSection}>
              <Box>
                <Button variant="contained" onClick={openCreatePanel}>
                  New Media
                </Button>
              </Box>
              <Box className={classes.selectorsRow}>
                <Box className={classes.searchContainer}>
                  <SearchInput
                    label=""
                    placeholder="Search by..."
                    onChange={handleChangeQuery}
                  />
                </Box>
                <Box className={classes.selectorsContainer}>
                  <Box className={classes.selectContainer}>
                    <Select
                      label="News Type"
                      value={categorizationId}
                      onChange={handleChangeCategorization}
                      options={categorizationOptions ?? []}
                      width={180}
                      displayAll
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box>
              <Table
                columns={TableColumns}
                data={tableData}
                defaultOrderBy="title"
                currentPage={page}
                setCurrentPage={setPage}
                loading={isFetching || isLoadingStatus}
              />
            </Box>
          </Section>
        </Box>
        {selectedNews ? (
          <EditNewsPanel
            data={selectedNews}
            categorizations={categorizations}
            onClose={resetSelected}
          />
        ) : null}
        {createPanelVisible ? (
          <EditNewsPanel
            categorizations={categorizations}
            onClose={closeCreatePanel}
          />
        ) : null}
      </Box>
    </Box>
  );
};

export default NewsPage;
