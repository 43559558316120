import { ChangeEvent, FC, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import ImgLogo from "../../assets/images/logo_small.svg";

interface Props {
  isLoading: boolean;
  onLogin: (email: string, password: string) => void;
}

const LoginForm: FC<Props> = ({ isLoading, onLogin }) => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.currentTarget.value);
  };
  const handleChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.currentTarget.value);
  };
  const onSubmit = () => {
    if (email && password) {
      onLogin(email, password);
    }
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box sx={{ width: "100%", backgroundColor: "#fff", padding: "2rem" }}>
        <Box textAlign="center">
          <img src={ImgLogo} alt="logo" />
        </Box>
        <Box>
          <FormGroup>
            <TextField
              id="email"
              name="email"
              label="Email or User Id"
              variant="standard"
              fullWidth
              sx={{ marginTop: "2rem" }}
              required
              onChange={handleChangeEmail}
            />
            <TextField
              id="password"
              name="password"
              label="Password"
              type="password"
              variant="standard"
              fullWidth
              sx={{ marginTop: "2rem" }}
              required
              onChange={handleChangePassword}
            />
            <FormControlLabel
              sx={{ marginTop: "1rem" }}
              control={<Checkbox defaultChecked />}
              label="Remember me"
            />
            <Button
              variant="contained"
              sx={{ marginTop: "1rem", fontSize: "1rem" }}
              disabled={isLoading}
              onClick={onSubmit}
            >
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Submit"
              )}
            </Button>
          </FormGroup>
        </Box>
      </Box>
    </Container>
  );
};

export default LoginForm;
