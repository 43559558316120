import { FC } from "react";
import { Box } from "@mui/material";
import { WebStatus } from "../../types";

interface Props {
  status: WebStatus;
}

const StatusBadge: FC<Props> = ({ status }) => {
  return (
    <Box
      sx={{
        backgroundColor:
          status === "A"
            ? "#ABC400"
            : status === "I"
            ? "#616870"
            : status === "H"
            ? "#EEE"
            : "#000",
        color: "#fff",
        textAlign: "center",
        padding: "0.5rem 1.5rem",
        borderRadius: "0.25rem",
        textTransform: "uppercase",
        fontSize: "0.5625rem",
        fontWeight: 600,
        width: "8rem",
      }}
    >
      {status === "A"
        ? "Activated"
        : status === "I"
        ? "Deactivated"
        : status === "H"
        ? "Hidden"
        : "Other"}
    </Box>
  );
};

export default StatusBadge;
