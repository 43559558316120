import { FC, useMemo, useState, useEffect } from "react";
import {
  Backdrop,
  Box,
  CircularProgress,
  TablePagination,
  TableSortLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TableDefault from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, {
  tableCellClasses,
  TableCellProps,
} from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import SortArrow from "./SortArrow";

const StyledTableCell = styled(TableCell, {
  shouldForwardProp: (prop) =>
    prop !== "dense" &&
    prop !== "nested" &&
    prop !== "subTableTitle" &&
    prop !== "textAlign" &&
    prop !== "sortable" &&
    prop !== "headerHeight",
})<{
  dense?: boolean;
  nested?: boolean;
  subTableTitle?: boolean;
  index?: number;
  headerHeight?: number | string;
  textAlign?: string;
  sortable?: boolean;
}>(
  ({
    theme,
    dense,
    nested,
    subTableTitle,
    index,
    width,
    headerHeight = "auto",
    textAlign = "left",
    sortable = false,
  }) => ({
    [`&.${tableCellClasses.head}`]: {
      background: "#1684FF",
      color: nested ? (subTableTitle ? "#235CFF" : "#616870") : "#2A2D31",
      height: nested && subTableTitle ? "4rem" : headerHeight,
      minWidth: nested && subTableTitle ? (index === 0 ? 120 : width) : "auto",
      fontWeight: 600,
      paddingTop: nested
        ? theme.spacing(1)
        : dense
        ? theme.spacing(2)
        : theme.spacing(3),
      paddingBottom: nested
        ? theme.spacing(1)
        : dense
        ? theme.spacing(2)
        : theme.spacing(3),
      paddingLeft: nested ? theme.spacing(1) : theme.spacing(2),
      paddingRight: nested ? theme.spacing(1) : theme.spacing(2),
      borderTop: nested ? "1px solid #235CFF" : "none",
      borderBottom: nested ? "1px solid #235CFF" : "none",
      textTransform: "uppercase",
      lineHeight: 1.2,
      textAlign,

      [theme.breakpoints.down("md")]: {
        fontSize: "13px",
        minWidth: nested && subTableTitle ? (index === 0 ? 80 : width) : "auto",
      },

      "& .MuiTableSortLabel-root.Mui-active": {
        color: "#efefef",
      },
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: "1rem",
      paddingTop: dense ? theme.spacing(1) : theme.spacing(2.5),
      paddingBottom: dense ? theme.spacing(1) : theme.spacing(2.5),
      paddingLeft: nested ? theme.spacing(1) : theme.spacing(2),
      paddingRight: nested
        ? theme.spacing(1)
        : sortable && textAlign === "right"
        ? theme.spacing(5)
        : theme.spacing(2),
      borderColor: theme.palette.primary.main,
      minWidth: nested && subTableTitle ? (index === 0 ? 120 : width) : "auto",
      lineHeight: 1.2,
      textAlign,

      [theme.breakpoints.down("md")]: {
        fontSize: "13px",
        minWidth: nested && subTableTitle ? (index === 0 ? 80 : width) : "auto",
      },
    },
    [`&.${tableCellClasses.sizeSmall}`]: {
      fontSize: "13px",
    },
  })
);

const StyledTableRow = styled(TableRow, {
  shouldForwardProp: (prop) => prop !== "isOdd",
})<{ isOdd?: boolean; dense?: boolean }>(({ theme, isOdd, dense }) => ({
  backgroundColor: theme.palette.common.white,
  paddingTop: dense ? theme.spacing(1) : theme.spacing(4.5),
  paddingBottom: dense ? theme.spacing(1) : theme.spacing(4.5),

  "&:nth-of-type(odd)": {
    backgroundColor: isOdd
      ? theme.palette.action.hover
      : theme.palette.common.white,
  },
  "&:first-of-type td, &:first-of-type th": {
    borderTopWidth: 0,
  },
}));

export interface RowData {
  [key: string]: any;
}

export interface Column extends TableCellProps {
  sortable?: boolean;
  label: string;
  shortLabel?: string;
  key: string;
  textAlign?: string;
  renderCell?: (props: RowData) => JSX.Element;
}

export interface Props {
  shouldHighlightOddRows?: boolean;
  columns: Column[];
  data: RowData[];
  dense?: boolean;
  nested?: boolean;
  hasPagination?: boolean;
  defaultOrderBy?: string;
  defaultOrder?: Order;
  headerHeight?: number | string;
  onPageChange?: (currentPage: number) => void;
  currentPage?: number;
  setCurrentPage?: (val: number) => void;
  loading?: boolean;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const Table: FC<Props> = ({
  columns,
  data = [],
  shouldHighlightOddRows,
  dense,
  nested,
  headerHeight,
  hasPagination = true,
  defaultOrder = "asc",
  defaultOrderBy,
  onPageChange,
  currentPage,
  setCurrentPage,
  loading,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [order, setOrder] = useState<Order>(defaultOrder);
  const [orderBy, setOrderBy] = useState<keyof RowData>();
  const [loadingVisible, setLoadingVisible] = useState(false);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    if (onPageChange) {
      onPageChange(newPage);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof RowData
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleHideLoading = () => {
    setLoadingVisible(false);
  };

  const createSortHandler =
    (property: keyof RowData) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property);
    };

  const rows = useMemo(
    () =>
      order && orderBy ? [...data].sort(getComparator(order, orderBy)) : data,
    [data, order, orderBy]
  );

  useEffect(() => {
    const sortableColumns = columns
      .filter((c) => !!c.sortable)
      .map((c) => c.key);
    if (defaultOrderBy && sortableColumns.includes(defaultOrderBy)) {
      setOrderBy(defaultOrderBy);
    }
  }, [columns, defaultOrderBy]);

  useEffect(() => {
    if (defaultOrder) {
      setOrder(defaultOrder);
    }
  }, [defaultOrder]);

  useEffect(() => {
    if (currentPage !== undefined && currentPage >= 0) {
      setPage(currentPage);
      if (setCurrentPage) {
        setCurrentPage(-1);
      }
    }
  }, [currentPage, setCurrentPage]);

  useEffect(() => {
    if (loading !== undefined) {
      setLoadingVisible(!!loading);
    }
  }, [loading]);

  return (
    <Box>
      <TableContainer component={Box}>
        <TableDefault sx={{ minWidth: { sm: 320, md: 400 } }}>
          <TableHead>
            <TableRow>
              {columns.map(
                (
                  {
                    key,
                    label,
                    sortable,
                    align = "left",
                    width,
                    textAlign,
                    ...rest
                  },
                  index
                ) => (
                  <StyledTableCell
                    key={key}
                    align={align}
                    textAlign={textAlign}
                    dense={dense}
                    nested={nested}
                    index={index}
                    subTableTitle={nested && !index}
                    width={width}
                    sortDirection={orderBy === key ? order : false}
                    headerHeight={headerHeight}
                    {...rest}
                  >
                    {sortable ? (
                      <TableSortLabel
                        hideSortIcon={false}
                        IconComponent={() => (
                          <SortArrow
                            direction={orderBy === key ? order : "asc"}
                          />
                        )}
                        active={orderBy === key}
                        direction={orderBy === key ? order : "asc"}
                        onClick={createSortHandler(key)}
                      >
                        {label}
                      </TableSortLabel>
                    ) : (
                      label
                    )}
                  </StyledTableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <StyledTableRow
                  key={row.id ?? index}
                  isOdd={shouldHighlightOddRows}
                >
                  {columns.map(
                    ({
                      key,
                      align,
                      textAlign,
                      sortable,
                      renderCell,
                      ...rest
                    }) => (
                      <StyledTableCell
                        key={key}
                        component="td"
                        scope="row"
                        align={align}
                        textAlign={textAlign}
                        dense={dense}
                        nested={nested}
                        sortable={sortable}
                        {...rest}
                      >
                        {renderCell ? renderCell(row) : row[key]}
                      </StyledTableCell>
                    )
                  )}
                </StyledTableRow>
              ))}
          </TableBody>
        </TableDefault>
      </TableContainer>
      {hasPagination && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingVisible}
        onClick={handleHideLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default Table;
