import { FC } from "react";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import ProtectedRoute from "./ProtectedRoute";

const MainLayout: FC<{}> = () => {
  return (
    <ProtectedRoute>
      <Box sx={{ display: "flex", height: "100%" }}>
        <Sidebar />
        <Box flex={1}>
          <Outlet />
        </Box>
      </Box>
    </ProtectedRoute>
  );
};

export default MainLayout;
