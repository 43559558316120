import { FC, ChangeEvent } from "react";
import { Box, Switch } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useUpdateTop10ConstituentsStatusMutation } from "../../../redux/queries/indexes";
import { Top10ConstituentsConfig, Status } from "../../../types";
import TextInput from "../../TextInput";
import { useStyles } from "../styles";

interface Props {
  index_id: string;
  top10ConstituentsStatus: Status;
  constituentsFileStatus: Status;
  customButtonStatus: Status;
  customButtonName: string;
  customButtonUrl: string;
  fileShouldBeDownloadable: Status;
}

const STATUS = {
  ACTIVE: "A",
  INACTIVE: "I",
};

const validationSchema = yup.object({
  custom_button_name: yup.string().required("Button name is required"),
  custom_button_url: yup.string().required("Button url is required"),
});

const EditSectionsConfig: FC<Props> = ({
  index_id,
  top10ConstituentsStatus,
  constituentsFileStatus,
  customButtonStatus,
  customButtonName,
  customButtonUrl,
  fileShouldBeDownloadable,
}) => {
  const classes = useStyles();
  const [updateTop10ConstituentsStatus] =
    useUpdateTop10ConstituentsStatusMutation();

  const initialData: Top10ConstituentsConfig = {
    index_id,
    top_10_constituents_status: top10ConstituentsStatus,
    constituents_file_status: constituentsFileStatus,
    custom_button_status: customButtonStatus,
    custom_button_name: customButtonName,
    custom_button_url: customButtonUrl,
    file_should_be_downloadable: fileShouldBeDownloadable,
  };

  const handleSaveInfo = (fieldName: string, value: string) => {
    updateTop10ConstituentsStatus({
      index_id: initialData.index_id,
      [fieldName]: value,
    });
  };

  const handleFieldChange = (fieldName: string, value: string) => {
    console.log("fieldName: ", fieldName, " value: ", value);

    updateTop10ConstituentsStatus({
      index_id,
      [fieldName]: value === STATUS.ACTIVE ? STATUS.INACTIVE : STATUS.ACTIVE,
    });
  };

  const renderSwitch = (
    label: string,
    status: Status,
    fieldName: string,
    value: string
  ) => {
    return (
      <Box className={classes.selectorsRow} mb={2}>
        <Box className={classes.primaryLabel}>{label}</Box>
        <Switch
          checked={status === STATUS.ACTIVE}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleFieldChange(fieldName, value)
          }
        />
      </Box>
    );
  };

  const { handleChange, touched, errors, values } =
    useFormik<Top10ConstituentsConfig>({
      initialValues: initialData,
      validationSchema: validationSchema,
      onSubmit: (values) => {
        const formData = new FormData();
        formData.set("custom_button_name", values.custom_button_name);
        formData.set("custom_button_url", values.custom_button_url);
      },
      enableReinitialize: true,
    });

  return (
    <Box py={2}>
      {renderSwitch(
        "Top 10 Constituents",
        top10ConstituentsStatus,
        "top_10_constituents_status",
        top10ConstituentsStatus
      )}
      {renderSwitch(
        "Constituents File",
        constituentsFileStatus,
        "constituents_file_status",
        constituentsFileStatus
      )}
      {renderSwitch(
        "Custom Button",
        customButtonStatus,
        "custom_button_status",
        customButtonStatus
      )}
      {customButtonStatus === "A" ? (
        renderSwitch(
          "File Should be Downloadable",
          fileShouldBeDownloadable,
          "file_should_be_downloadable",
          fileShouldBeDownloadable
        )
      ) : (
        <></>
      )}

      <TextInput
        name="custom_button_name"
        label="Name"
        value={values.custom_button_name ?? ""}
        error={touched.custom_button_name && Boolean(errors.custom_button_name)}
        helperText={touched.custom_button_name && errors.custom_button_name}
        fullWidth
        onChange={handleChange}
        onSave={handleSaveInfo}
        maxLength={99}
      />

      <TextInput
        name="custom_button_url"
        label="Link"
        value={values.custom_button_url ?? ""}
        error={touched.custom_button_url && Boolean(errors.custom_button_url)}
        helperText={touched.custom_button_url && errors.custom_button_url}
        fullWidth
        onChange={handleChange}
        onSave={handleSaveInfo}
        maxLength={99}
      />
    </Box>
  );
};

export default EditSectionsConfig;
