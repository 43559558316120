import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { pageStyles } from "../../theme";

export const useStyles = makeStyles((theme: Theme) => ({
  ...pageStyles(theme),
  root: {},
  container: {
    display: "flex",
    flex: 1,
    overflow: "auto",
  },
  tableBox: {
    flex: 2,
    marginRight: "1.5rem",
  },
  table: {
    marginTop: "1.5rem",
  },
  materialUploadBox: {
    flex: 1,
  },
  selectorsRow: {
    width: '100%',
    display: "flex",
    marginTop: "1.5rem",
    justifyContent: "space-between",
  },
  searchContainer: {
    paddingLeft: "2rem",
  },
  selectorsContainer: {
    display: "flex",
  },
  selectContainer: {
    paddingLeft: "2rem",
  },
  loader: {
    padding: "10rem 0",
    textAlign: "center",
  },
}));
