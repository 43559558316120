import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { authApi } from "../queries/auth";
import { RootState } from "../store";

export interface AuthState {
  userId: string;
  token: string;
}

export const STORE_RESET = "store/reset";

const initialState: AuthState = {
  userId: "",
  token: "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserId: (state, action: PayloadAction<string>) => {
      state.userId = action.payload;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    logout: (state) => {
      state.token = "";
      state.userId = "";
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.login.matchFulfilled,
      (state, { payload, meta }) => {
        // Update state with the access token from the login response
        state.userId = meta.arg.originalArgs.user;
        state.token = payload.token;
      }
    );
  },
});

export const { setToken, setUserId, logout } = authSlice.actions;

export const selectUserId = (state: RootState) => state.auth.userId;
export const selectToken = (state: RootState) => state.auth.token;

export default authSlice.reducer;
