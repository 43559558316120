import { ChangeEvent, FC } from "react";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Box, CircularProgress } from "@mui/material";
import { WebStatus } from "../../types";
import { useStyles } from "../EditIndexPanel/styles";

interface Props {
  label: string;
  filename: string;
  uploadDate?: string;
  status?: WebStatus;
  disabled: boolean;
  loading: boolean;
  acceptFileFormat?: string;
  setFile: (file?: File) => void;
  onToggleStatus?: (newStatus: WebStatus) => void;
  onSubmit?: () => void;
  error?: boolean;
}

const FileUploadInput: FC<Props> = ({
  label,
  filename,
  uploadDate = "N/A",
  status,
  loading,
  setFile,
  onToggleStatus,
  onSubmit,
  disabled,
  acceptFileFormat,
  error = false,
}) => {
  const classes = useStyles();

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    setFile(e.target.files?.[0]);
  };

  return (
    <Box py={1}>
      <Box className={classes.primaryLabel}>{label}</Box>
      <Box className={error ? classes.fileNameRequired : classes.fileName}>
        {filename}
      </Box>
      <Box className={classes.fileInputFooter}>
        <Box className={error ? classes.required : classes.textDisabled}>
          {error ? "File is required" : `Last Upload: ${uploadDate}`}
        </Box>
        <Box className={classes.actionsContainer}>
          {status && (
            <Box
              className={classes.actionIcon}
              onClick={() => onToggleStatus?.(status)}
            >
              {status === "A" ? (
                <VisibilityOutlinedIcon
                  fontSize="small"
                  sx={{ marginRight: 1 }}
                />
              ) : (
                <VisibilityOffOutlinedIcon
                  fontSize="small"
                  sx={{ marginRight: 1 }}
                />
              )}
            </Box>
          )}
          <Box component="label" className={classes.actionIcon}>
            <CloudUploadOutlinedIcon fontSize="small" sx={{ marginRight: 1 }} />
            <input
              name="file"
              type="file"
              hidden
              accept={acceptFileFormat}
              onChange={handleChangeInput}
            />
          </Box>
          {onSubmit && (
            <Box
              className={`${classes.actionIcon} ${
                disabled ? classes.actionDisabled : ""
              }`}
              onClick={onSubmit}
            >
              <Box sx={{ marginRight: 1 }}>
                {loading ? (
                  <CircularProgress size={16} color="inherit" />
                ) : (
                  <CheckOutlinedIcon fontSize="small" />
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default FileUploadInput;
