import { ChangeEvent, useEffect, useMemo, useState } from "react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Box, Button, SelectChangeEvent, Switch } from "@mui/material";
import EditIndexLabPanel from "../../components/EditIndexLabPanel";
import SearchInput from "../../components/SearchInput";
import Section from "../../components/Section";
import Select from "../../components/Select";
import StatusBadge from "../../components/StatusBadge";
import Table, { Column } from "../../components/Table";
import {
  useFetchAssetClassesQuery,
  useFetchFamiliesIndexesQuery,
  useFetchIndexTypesQuery,
} from "../../redux/queries/indexes";
import {
  useFetchIndexLabsQuery,
  useUpdateIndexLabInfoMutation,
  useUpdateIndexLabPrototypeMutation,
} from "../../redux/queries/indexLab";
import { IndexLab } from "../../types/indexLab";
import { useStyles } from "./styles";

const IndexLabPage = () => {
  const classes = useStyles();

  const { data, isFetching } = useFetchIndexLabsQuery({});
  const { data: assetClasses } = useFetchAssetClassesQuery({});
  const { data: indexTypes } = useFetchIndexTypesQuery({});
  const { data: families } = useFetchFamiliesIndexesQuery({});
  const [updateInfo] = useUpdateIndexLabInfoMutation();
  const [updatePrototype] = useUpdateIndexLabPrototypeMutation();

  const [query, setQuery] = useState<string>("");
  const [page, setPage] = useState<number>(-1);
  const [selected, setSelected] = useState<number>();
  const [assetClass, setAssetClass] = useState<number>();
  const [indexType, setIndexType] = useState<number>();
  const [createPanelVisible, setCreatePanelVisible] = useState<boolean>(false);

  const openCreatePanel = () => setCreatePanelVisible(true);
  const closeCreatePanel = () => setCreatePanelVisible(false);

  const selectedIndexLab = useMemo(
    () => data?.find((indexLab) => indexLab.index_lab_id === selected),
    [data, selected]
  );

  const handleChangeAssetClass = (event: SelectChangeEvent<any>) => {
    setAssetClass(event.target.value);
  };

  const handleChangeIndexType = (event: SelectChangeEvent<any>) => {
    setIndexType(event.target.value);
  };

  const handleChangeQuery = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  const handleChangeStatus = (
    e: ChangeEvent<HTMLInputElement>,
    data: IndexLab
  ) => {
    updateInfo({
      ...data,
      id: data.index_lab_id,
      status: data.status === "A" ? "I" : "A",
    });
  };

  const handleChangePrototype = (
    e: ChangeEvent<HTMLInputElement>,
    data: IndexLab
  ) => {
    updatePrototype({ id: data.index_lab_id });
  };

  const resetSelected = () => setSelected(undefined);

  const TableColumns: Column[] = [
    {
      key: "index_name",
      label: "Title",
      sortable: true,
      colSpan: 3,
      width: "60%",
      renderCell: (data) => (
        <Box sx={{ color: "primary.main", fontWeight: "bold" }}>
          {data.index_name}
        </Box>
      ),
    },
    {
      key: "web_status",
      label: "Status",
      sortable: true,
      renderCell: (data) => <StatusBadge status={data.status} />,
    },
    {
      key: "option",
      label: "Options",
      sortable: false,
      renderCell: (data) => (
        <Box
          sx={{ cursor: "pointer" }}
          onClick={() => setSelected(data.index_lab_id)}
        >
          <EditOutlinedIcon />
        </Box>
      ),
    },
    {
      key: "activation",
      label: "Activation",
      sortable: false,
      renderCell: (data) => (
        <Switch
          checked={data.status === "A"}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleChangeStatus(e, data as IndexLab)
          }
        />
      ),
    },
    {
      key: "index_prototype",
      label: "Prototype",
      sortable: false,
      renderCell: (data) => (
        <Switch
          checked={data.index_prototype}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleChangePrototype(e, data as IndexLab)
          }
        />
      ),
    },
  ];

  const assetClassOptions = useMemo(() => {
    if (!assetClasses) return [];
    return assetClasses.map(({ id, name }) => ({
      label: name,
      value: id,
    }));
  }, [assetClasses]);

  const indexTypeOptions = useMemo(() => {
    if (!indexTypes) return [];
    return indexTypes.map(({ id, name }) => ({
      value: id,
      label: name,
    }));
  }, [indexTypes]);

  const visibleKeys = TableColumns.map((col) => col.key);

  const tableData = useMemo(() => {
    if (!data) return [];

    return data
      .filter((indexLab) => {
        if (assetClass && indexLab.asset_class.id !== assetClass) {
          return false;
        }
        if (indexType && indexLab.index_type.id !== indexType) {
          return false;
        }
        if (query?.length > 2) {
          return visibleKeys.some(
            (k) =>
              (indexLab as any)[k] &&
              ((indexLab as any)[k] as any)
                .toString()
                .toLowerCase()
                .includes(query.toLowerCase())
          );
        }
        return true;
      })
      .map((indexLab) => ({
        ...indexLab,
        index_name: indexLab.index_name.trim(),
      }));
  }, [assetClass, data, indexType, query, visibleKeys]);

  // Reset index family which depends on asset class
  useEffect(() => {
    setIndexType(undefined);
  }, [assetClass]);

  useEffect(() => {
    // Reset table page to 0 when either asset class or index family changes
    // Required because total number of rows depends on asset class & index family selections
    setPage(0);
  }, [assetClass, indexType]);

  useEffect(() => {
    // Reset table page to 0 when search query gets updated
    if (query.length >= 3) {
      setPage(0);
    }
  }, [query]);

  return (
    <Box className={classes.page}>
      <Box className={classes.pageHeader}>Index Lab</Box>
      <Box className={classes.container}>
        <Box className={classes.tableBox}>
          <Section title="Indexes list">
            <Box className={classes.headerSection}>
              <Box>
                <Button variant="contained" onClick={openCreatePanel}>
                  New Index Lab
                </Button>
              </Box>
              <Box className={classes.selectorsRow}>
                <Box className={classes.searchContainer}>
                  <SearchInput
                    label=""
                    placeholder="Search by..."
                    onChange={handleChangeQuery}
                  />
                </Box>
                <Box className={classes.selectorsContainer}>
                  <Box className={classes.selectContainer}>
                    <Select
                      label="Asset Class"
                      value={assetClass}
                      onChange={handleChangeAssetClass}
                      options={assetClassOptions}
                      width={180}
                      displayAll
                    />
                  </Box>
                  <Box className={classes.selectContainer}>
                    <Select
                      label="Index Family"
                      value={indexType}
                      onChange={handleChangeIndexType}
                      options={indexTypeOptions}
                      width={180}
                      disabled={!assetClass}
                      displayAll
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box>
              <Table
                columns={TableColumns}
                data={tableData}
                defaultOrderBy="name"
                currentPage={page}
                setCurrentPage={setPage}
                loading={isFetching}
              />
            </Box>
          </Section>
        </Box>
        {selectedIndexLab ? (
          <EditIndexLabPanel
            data={selectedIndexLab}
            assetClasses={assetClasses ?? []}
            indexTypes={indexTypes ?? []}
            families={families ?? {}}
            onClose={resetSelected}
          />
        ) : null}
        {createPanelVisible ? (
          <EditIndexLabPanel
            assetClasses={assetClasses ?? []}
            indexTypes={indexTypes ?? []}
            families={families ?? {}}
            onClose={closeCreatePanel}
          />
        ) : null}
      </Box>
    </Box>
  );
};

export default IndexLabPage;
