import { RTK_SLICE_API_LIST } from "./constants";

export const getTags = (reducerPath: string, key: string, result?: any[]) => [
  ...(result ?? []).map(
    (item) =>
      ({
        type: reducerPath as any,
        id: item[key]?.toString(),
      } as const)
  ),
  { type: reducerPath as any, id: RTK_SLICE_API_LIST },
];

export const convertObjToFormData = (obj: { [key: string]: any }) => {
  const formData = new FormData();
  Object.entries(obj).forEach(([key, value]) => {
    if (value !== undefined) {
      formData.append(key, value);
    }
  });
  return formData;
};
