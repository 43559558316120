import { FC, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import * as yup from "yup";
import {
  useUpdateCryptocurrencyBackgroundMutation,
  useUpdateCryptocurrencyInfoMutation,
  useUpdateCryptocurrencyLogoMutation,
} from "../../../redux/queries/cryptocurrencies";
import { Cryptocurrency } from "../../../types/cryptocurrencies";
import FileUploadInput from "../../FileUploadInput";
import TextInput from "../../TextInput";

interface Props {
  data?: Cryptocurrency;
  onClose: () => void;
}

const validationSchema = yup.object({
  name: yup.string().required("Name is required"),
  ticker: yup.string().required("Ticker is required"),
  description: yup.string().required("Description is required"),
});

const EditCryptocurrencyForm: FC<Props> = ({ data, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [updateInfo, { isError, isSuccess }] =
    useUpdateCryptocurrencyInfoMutation();
  const [
    updateLogo,
    {
      isSuccess: isSuccessUpdateLogo,
      isError: isErrorUpdateLogo,
      isLoading: isLoadingUpdateLogo,
    },
  ] = useUpdateCryptocurrencyLogoMutation();
  const [
    updateBackground,
    {
      isSuccess: isSuccessUpdateBg,
      isError: isErrorUpdateBg,
      isLoading: isLoadingUpdateBg,
    },
  ] = useUpdateCryptocurrencyBackgroundMutation();

  const initialData: Cryptocurrency = data?.crypto_id
    ? data
    : {
        crypto_id: -1,
        name: "",
        description: "",
        ticker: "",
        background_image_url: "",
        image_url: "",
        status: "A",
      };

  const [localBgImg, setLocalBgImg] = useState<File>();
  const [localImg, setLocalImg] = useState<File>();

  const { handleChange, handleSubmit, touched, errors, values } =
    useFormik<Cryptocurrency>({
      initialValues: initialData,
      validationSchema: validationSchema,
      onSubmit: (values) => {},
      enableReinitialize: true,
    });

  const handleSaveInfo = (fieldName: string, value: string) => {
    updateInfo({
      ticker: initialData.ticker,
      [fieldName]: value,
    });
  };

  const handleSaveLogo = () => {
    if (localImg) {
      const formData = new FormData();
      formData.set("file", localImg as any);
      updateLogo({
        ticker: initialData.ticker,
        formData,
      });
    }
  };

  const handleSaveBackground = () => {
    if (localBgImg) {
      const formData = new FormData();
      formData.set("file", localBgImg as any);
      updateBackground({
        ticker: initialData.ticker,
        formData,
      });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar({
        message: "Successfully updated cryptocurrency.",
        variant: "success",
        autoHideDuration: 3000,
      });
      onClose();
    }
  }, [enqueueSnackbar, isSuccess, onClose]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar({
        message: "Failed to update cryptocurrency.",
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }, [enqueueSnackbar, isError]);

  useEffect(() => {
    if (isSuccessUpdateBg) {
      enqueueSnackbar({
        message: "Successfully updated background.",
        variant: "success",
        autoHideDuration: 3000,
      });
      setLocalBgImg(undefined);
      onClose();
    }
  }, [enqueueSnackbar, isSuccessUpdateBg, onClose]);

  useEffect(() => {
    if (isErrorUpdateBg) {
      enqueueSnackbar({
        message: "Failed to update background.",
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }, [enqueueSnackbar, isErrorUpdateBg]);

  useEffect(() => {
    if (isSuccessUpdateLogo) {
      enqueueSnackbar({
        message: "Successfully updated logo.",
        variant: "success",
        autoHideDuration: 3000,
      });
      setLocalImg(undefined);
      onClose();
    }
  }, [enqueueSnackbar, isSuccessUpdateLogo, onClose]);

  useEffect(() => {
    if (isErrorUpdateLogo) {
      enqueueSnackbar({
        message: "Failed to update logo.",
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }, [enqueueSnackbar, isErrorUpdateLogo]);

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <TextInput
          name="name"
          label="Name"
          value={values.name}
          error={touched.name && Boolean(errors.name)}
          helperText={touched.name && errors.name}
          fullWidth
          placeholder="Type name..."
          onChange={handleChange}
          onSave={handleSaveInfo}
        />
        <TextInput
          name="description"
          label="Description"
          value={values.description}
          error={touched.description && Boolean(errors.description)}
          helperText={touched.description && errors.description}
          fullWidth
          placeholder="Type description..."
          onChange={handleChange}
          onSave={handleSaveInfo}
          maxLength={1000}
        />
        <Box>
          <FileUploadInput
            label="Background Image File"
            filename={localBgImg?.name ?? initialData.background_image_url}
            setFile={setLocalBgImg}
            disabled={!localBgImg}
            loading={isLoadingUpdateBg}
            onSubmit={handleSaveBackground}
            acceptFileFormat="image/*"
          />
        </Box>
        <Box>
          <FileUploadInput
            label="Logo File"
            filename={localImg?.name ?? initialData.image_url}
            setFile={setLocalImg}
            disabled={!localImg}
            loading={isLoadingUpdateLogo}
            onSubmit={handleSaveLogo}
            acceptFileFormat="image/*"
          />
        </Box>
      </form>
    </Box>
  );
};

export default EditCryptocurrencyForm;
