import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  editBox: {
    width: "30rem",
  },
  primaryLabel: {
    color: "#235CFF",
    fontWeight: 700,
  },
  selectorsRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  actionsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  actionIcon: {
    cursor: "pointer",
  },
  actionDisabled: {
    cursor: "not-allowed",
    color: "rgba(0,0,0,0.38)",
  },
  textDisabled: {
    fontSize: "0.75rem",
    color: "rgba(0,0,0,0.38)",
  },
  fileName: {
    fontSize: 15,
    padding: "0.5rem 1rem",
    marginBottom: "0.5rem",
    color: "rgba(0,0,0,0.38)",
    borderBottom: "1px solid rgba(0,0,0,0.38)",
  },
  fileNameRequired: {
    fontSize: 15,
    padding: "0.5rem 1rem",
    marginBottom: "0.5rem",
    borderBottom: "1px solid #d32f2f",
  },
  fileInputFooter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  closeContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    background: "#fff",
    padding: "0.75rem",
    marginBottom: "-1.5rem",
  },
  closeIcon: {
    cursor: "pointer",
  },
  required: {
    fontSize: "0.75rem",
    color: "#d32f2f !important",
  }
}));
