import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  editBox: {
    width: "40rem",
  },
  closeContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    background: "#fff",
    padding: "0.75rem",
    marginBottom: "-1.5rem",
  },
  closeIcon: {
    cursor: "pointer",
  },
  submitLoaderWrapper: {
    display: "flex",
    padding: "0.125rem 3rem"
  }
}));
