import { ChangeEvent, useEffect, useMemo, useState } from "react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Box, Link, SelectChangeEvent } from "@mui/material";
import EditIndexPanel from "../../components/EditIndexPanel";
import SearchInput from "../../components/SearchInput";
import Section from "../../components/Section";
import Select from "../../components/Select";
import StatusBadge from "../../components/StatusBadge";
import Table, { Column } from "../../components/Table";
import {
  useFetchAssetClassesQuery,
  useFetchFamiliesIndexesQuery,
  useFetchIndexListQuery,
  useFetchIndexTypesQuery,
} from "../../redux/queries/indexes";
import { useStyles } from "./styles";

const IndexManagementPage = () => {
  const classes = useStyles();
  const { data: indexList, isFetching } = useFetchIndexListQuery({});
  const { data: families } = useFetchFamiliesIndexesQuery({});
  const { data: assetClasses } = useFetchAssetClassesQuery({});
  const { data: indexTypes } = useFetchIndexTypesQuery({});

  const [assetClass, setAssetClass] = useState<string>("");
  const [indexFamily, setIndexFamily] = useState<string>("");
  const [query, setQuery] = useState<string>("");
  const [page, setPage] = useState<number>(-1);
  const [selected, setSelected] = useState<string>("");

  const selectedIndex = useMemo(
    () => indexList?.find((index) => index.index_id === selected),
    [indexList, selected]
  );

  const handleChangeAssetClass = (event: SelectChangeEvent<any>) => {
    setAssetClass(event.target.value);
  };

  const handleChangeIndexFamily = (event: SelectChangeEvent<any>) => {
    setIndexFamily(event.target.value);
  };

  const handleChangeQuery = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  const resetSelected = () => setSelected("");

  const WEBSITE_V3_URL =
    process.env.REACT_APP_WEBSITE_V3_URL ?? "http://localhost:3000";

  const TableColumns: Column[] = [
    {
      key: "name",
      label: "Index name",
      sortable: true,
      colSpan: 3,
      width: "60%",
      renderCell: (data) => (
        <Link
          href={`${WEBSITE_V3_URL}/index/${data.ticker}`}
          target="_blank"
          sx={{ textDecoration: "none" }}
        >
          <Box sx={{ color: "primary.main", fontWeight: "bold" }}>
            {data.name}
          </Box>
        </Link>
      ),
    },
    {
      key: "ticker",
      label: "Ticker",
      sortable: true,
      renderCell: (data) => <Box>{data.ticker}</Box>,
    },
    {
      key: "web_status",
      label: "Type",
      sortable: true,
      renderCell: (data) => <StatusBadge status={data.web_status} />,
    },
    {
      key: "option",
      label: "Options",
      sortable: false,
      renderCell: (data) => (
        <Box
          sx={{ cursor: "pointer" }}
          onClick={() => setSelected(data.index_id)}
        >
          <EditOutlinedIcon />
        </Box>
      ),
    },
  ];

  const assetClassOptions = useMemo(() => {
    if (!families) return [];
    return Object.keys(families).map((k) => ({
      label: k,
      value: k,
    }));
  }, [families]);

  const indexFamilyOptions = useMemo(() => {
    if (!families || !assetClass || !families[assetClass]) return [];
    return Object.keys(families[assetClass]).map((k) => ({
      value: k,
      label: k,
    }));
  }, [assetClass, families]);

  const tableData = useMemo(() => {
    if (!indexList) return [];

    return indexList
      .filter((index) => {
        if (!families) return true;

        if (!assetClass) return true;

        if (!indexFamily) {
          const range = Object.keys(families[assetClass]).flatMap(
            (key) => families[assetClass][key]
          );

          return !!range.find((item) => item.symbol === index.ticker);
        }
        return !!families[assetClass][indexFamily]?.find(
          (item) => item.symbol === index.ticker
        );
      })
      .map((index) => ({ ...index, name: index.name.trim() }));
  }, [assetClass, families, indexFamily, indexList]);

  const visibleKeys = TableColumns.map((col) => col.key);

  // Filter table rows by "search" input value
  const filteredTableData = useMemo(() => {
    if (query?.length < 3) return tableData;
    return tableData.filter((item) => {
      return visibleKeys.some(
        (k) =>
          (item as any)[k] &&
          ((item as any)[k] as any)
            .toString()
            .toLowerCase()
            .includes(query.toLowerCase())
      );
    });
  }, [query, tableData, visibleKeys]);

  // Reset index family which depends on asset class
  useEffect(() => {
    setIndexFamily("");
  }, [assetClass]);

  useEffect(() => {
    // Reset table page to 0 when either asset class or index family changes
    // Required because total number of rows depends on asset class & index family selections
    setPage(0);
  }, [assetClass, indexFamily]);

  useEffect(() => {
    // Reset table page to 0 when search query gets updated
    if (query.length >= 3) {
      setPage(0);
    }
  }, [query]);

  return (
    <Box className={classes.page}>
      <Box className={classes.pageHeader}>Index management</Box>
      <Box className={classes.container}>
        <Box className={classes.tableBox}>
          <Section title="Indexes list">
            <Box className={classes.selectorsRow}>
              <Box className={classes.searchContainer}>
                <SearchInput
                  label=""
                  placeholder="Search by..."
                  onChange={handleChangeQuery}
                />
              </Box>
              <Box className={classes.selectorsContainer}>
                <Box className={classes.selectContainer}>
                  <Select
                    label="Asset Class"
                    value={assetClass}
                    onChange={handleChangeAssetClass}
                    options={assetClassOptions}
                    width={180}
                    displayAll
                  />
                </Box>
                <Box className={classes.selectContainer}>
                  <Select
                    label="Index Family"
                    value={indexFamily}
                    onChange={handleChangeIndexFamily}
                    options={indexFamilyOptions}
                    width={180}
                    disabled={!assetClass}
                    displayAll
                  />
                </Box>
              </Box>
            </Box>
            <Box>
              <Table
                columns={TableColumns}
                data={filteredTableData}
                defaultOrderBy="name"
                currentPage={page}
                setCurrentPage={setPage}
                loading={isFetching}
              />
            </Box>
          </Section>
        </Box>
        {selectedIndex ? (
          <EditIndexPanel
            data={selectedIndex}
            families={families}
            assetClasses={assetClasses ?? []}
            indexTypes={indexTypes ?? []}
            onClose={resetSelected}
          />
        ) : null}
      </Box>

    </Box>
  );
};

export default IndexManagementPage;
