import { FC, useEffect, useState } from "react";
import { capitalize } from "@mui/material";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import {
  useCreateIndexFileMutation,
  useUpdateIndexFileMutation,
  useUpdateIndexFileStatusMutation,
} from "../../../../redux/queries/indexFiles";
import { IndexFileType, WebStatus } from "../../../../types";
import FileUploadInput from "../../../FileUploadInput";

interface Props {
  indexId: string;
  fileId: number;
  fileKey: string;
  type: IndexFileType;
  status?: WebStatus;
  createDate: string;
}

const IndexFileInput: FC<Props> = ({
  indexId,
  fileId,
  fileKey,
  type,
  status,
  createDate,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [
    createIndexFile,
    {
      isError: isErrorCreateIndexFile,
      isSuccess: isSuccessCreateIndexFile,
      isLoading: isLoadingCreateIndexFile,
    },
  ] = useCreateIndexFileMutation();
  const [
    updateIndexFile,
    {
      isError: isErrorUpdateIndexFile,
      isSuccess: isSuccessUpdateFileIndex,
      isLoading: isLoadingUpdateIndexFile,
    },
  ] = useUpdateIndexFileMutation();
  const [
    updateFileStatus,
    { isError: isErrorFileStatus, isSuccess: isSuccessFileStatus },
  ] = useUpdateIndexFileStatusMutation();

  const [localFile, setLocalFile] = useState<File>();

  const label = type
    .split("_")
    .map((str) => capitalize(str))
    .join(" ");

  const uploadDate = createDate
    ? DateTime.fromISO(createDate).toFormat("yyyy-LL-dd")
    : "N/A";

  const onSubmit = () => {
    if (localFile) {
      const formData = new FormData();
      formData.set("file", localFile as any);

      if (fileId < 0) {
        formData.set("name", type);
        formData.set("index_id", indexId);
        formData.set("status", "A");
        createIndexFile({
          formData,
        });
      } else {
        updateIndexFile({
          file_id: fileId.toString(),
          formData,
        });
      }
    }
  };

  const onToggleStatus = () => {
    updateFileStatus({
      index_id: indexId,
      file_id: fileId.toString(),
      status: status === "A" ? "I" : "A",
    });
  };

  useEffect(() => {
    if (isErrorUpdateIndexFile || isErrorCreateIndexFile) {
      enqueueSnackbar({
        message: `Failed to ${
          isErrorCreateIndexFile ? "create" : "update"
        } index file.`,
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }, [enqueueSnackbar, isErrorCreateIndexFile, isErrorUpdateIndexFile]);

  useEffect(() => {
    if (isSuccessCreateIndexFile || isSuccessUpdateFileIndex) {
      enqueueSnackbar({
        message: "File successfully uploaded.",
        variant: "success",
        autoHideDuration: 3000,
      });
    }
  }, [enqueueSnackbar, isSuccessCreateIndexFile, isSuccessUpdateFileIndex]);

  useEffect(() => {
    if (isErrorFileStatus) {
      enqueueSnackbar({
        message: "Failed to update file status.",
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }, [enqueueSnackbar, isErrorFileStatus]);

  useEffect(() => {
    if (isSuccessFileStatus) {
      enqueueSnackbar({
        message: "File status successfully updated.",
        variant: "success",
        autoHideDuration: 3000,
      });
    }
  }, [enqueueSnackbar, isSuccessFileStatus]);

  return (
    <FileUploadInput
      key={fileId}
      label={label}
      filename={localFile?.name ?? fileKey}
      status={status}
      setFile={setLocalFile}
      onToggleStatus={onToggleStatus}
      onSubmit={onSubmit}
      disabled={!localFile}
      loading={isLoadingCreateIndexFile || isLoadingUpdateIndexFile}
      uploadDate={uploadDate}
      acceptFileFormat="application/pdf"
    />
  );
};

export default IndexFileInput;
