import { createApi } from "@reduxjs/toolkit/query/react";
import { ApiResponseData, WebStatus, IndexFileDetails } from "../../types";
import { RTK_SLICE_API_LIST } from "../../utils/constants";
import { getTags } from "../../utils/rtk-query";
import { baseQuery } from "./base";

const reducerPath = "indexe-files";

export const indexFilesApi = createApi({
  reducerPath,
  baseQuery: baseQuery,
  tagTypes: [reducerPath],
  endpoints: (builder) => ({
    fetchIndexFiles: builder.query({
      query: ({ indexSymbol }: { indexSymbol: string }) =>
        `/v2/index/${indexSymbol}/files`,
      transformResponse: (responseData: ApiResponseData<IndexFileDetails[]>) =>
        responseData?.data ?? [],
      providesTags: (result) => getTags(reducerPath, "id_m_index_file", result),
    }),
    fetchIndexFile: builder.query({
      query: ({ fileId }: { fileId: string }) =>
        `/v2/index/file?file_id=${fileId}`,
      transformResponse: (responseData: ApiResponseData<IndexFileDetails>) =>
        responseData?.data ?? [],
    }),
    createIndexFile: builder.mutation({
      query: ({ formData }: { formData: FormData }) => ({
        url: `/v2/index/file`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: () => [{ type: reducerPath, id: RTK_SLICE_API_LIST }],
    }),
    updateIndexFile: builder.mutation({
      query: ({
        file_id,
        formData,
      }: {
        file_id: string;
        formData: FormData;
      }) => ({
        url: `/v2/index/file/${file_id}`,
        method: "PUT",
        body: formData,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: reducerPath, id: arg.file_id },
      ],
    }),
    updateIndexFileStatus: builder.mutation({
      query: ({
        file_id,
        status,
      }: {
        index_id: string;
        file_id: string;
        status: WebStatus;
      }) => ({
        url: `/v2/index/file/status/${file_id}`,
        method: "PUT",
        body: { status },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: reducerPath, id: arg.file_id },
      ],
    }),
  }),
});

export const {
  useUpdateIndexFileMutation,
  useUpdateIndexFileStatusMutation,
  useCreateIndexFileMutation,
  useFetchIndexFileQuery,
  useFetchIndexFilesQuery,
} = indexFilesApi;
