import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useLocation, useNavigate } from "react-router-dom";
import ImgLogo from "../../assets/images/text_logo.svg";
import { useAppDispatch } from "../../hooks/useStore";
import { STORE_RESET, logout } from "../../redux/slices/auth";

export const drawerWidth = 240;

export interface MenuItem {
  id: number;
  label: string;
  href?: string;
  onClick?: () => void;
  hidden?: boolean;
}

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const onLogout = () => {
    dispatch(logout());
    // reset store data
    dispatch({
      type: STORE_RESET,
    });
    enqueueSnackbar({
      message: "You are logged out successfully.",
      variant: "info",
    });
  };

  const onClickItem = (item: MenuItem) => {
    if (item.onClick) {
      return item.onClick();
    }
    if (item.href) {
      navigate(item.href);
    }
    return false;
  };

  const ITEMS: MenuItem[] = [
    {
      id: 0,
      label: "index management",
      href: "/",
    },
    {
      id: 1,
      label: "announcements",
      href: "/announcements",
    },
    {
      id: 2,
      label: "news",
      href: "/news",
    },
    {
      id: 3,
      label: "governance / other",
      href: "/governance",
    },
    {
      id: 4,
      label: "index lab",
      href: "/index-lab",
    },
    {
      id: 5,
      label: "cryptocurrencies",
      href: "/cryptocurrencies",
    },
    {
      id: 6,
      label: "logout",
      onClick: onLogout,
    },
  ];

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Box
        sx={{
          background: "linear-gradient(90deg, #4995FF 0%, #235CFF 100%)",
          padding: "1rem",
          textAlign: "center",
        }}
      >
        <img src={ImgLogo} alt="Text Logo" height="100%" />
      </Box>
      <List>
        {ITEMS.filter((item) => !item.hidden).map((item) => {
          const active = item.href === location.pathname;
          return (
            <ListItem key={item.id} disablePadding>
              <ListItemButton
                sx={{
                  textTransform: "uppercase",
                  fontWeight: "bold",
                  color: active ? "#fff" : "#1684FF",
                  backgroundColor: active ? "#1684FF" : "#fff",
                  ":hover": {
                    color: active ? "#eee" : "#1684FF",
                    backgroundColor: active ? "#1684FF" : "#eee",
                  },
                }}
              >
                <ListItemText
                  primary={item.label}
                  onClick={() => onClickItem(item)}
                />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Drawer>
  );
};

export default Sidebar;
