import { FC } from "react";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

const SimpleLayout: FC<{}> = () => {
  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
      }}
    >
      <Outlet />
    </Box>
  );
};

export default SimpleLayout;
