import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { Box, Button, Link, SelectChangeEvent, Switch } from "@mui/material";
import AnnouncementTypeCell from "../../components/AnnouncementTypeCell";
import EditAnnouncementPanel from "../../components/EditAnnouncementPanel";
import SearchInput from "../../components/SearchInput";
import Section from "../../components/Section";
import Select from "../../components/Select";
import Table, { Column } from "../../components/Table";
import {
  useFetchAnnouncementCategoriesQuery,
  useFetchAnnouncementsQuery,
  useUpdateAnnouncementFileStatusMutation,
} from "../../redux/queries/announcements";
import { Announcement } from "../../types";
import { useStyles } from "./styles";

const AnnouncementsPage = () => {
  const classes = useStyles();
  const { data: announcements, isFetching } = useFetchAnnouncementsQuery({});
  const { data: categories } = useFetchAnnouncementCategoriesQuery({});
  const [updateAnnouncementFileStatus] =
    useUpdateAnnouncementFileStatusMutation();

  const [category, setCategory] = useState<number>();
  const [query, setQuery] = useState<string>("");
  const [page, setPage] = useState<number>(-1);
  const [selected, setSelected] = useState<number>();
  const [createPanelVisible, setCreatePanelVisible] = useState<boolean>(false);

  const openCreatePanel = () => setCreatePanelVisible(true);
  const closeCreatePanel = () => setCreatePanelVisible(false);

  const selectedItem = useMemo(
    () =>
      announcements?.find((index) => index.id_m_web_announcement === selected),
    [announcements, selected]
  );

  const handleChangeCategory = (event: SelectChangeEvent<any>) => {
    setCategory(event.target.value);
  };

  const handleChangeQuery = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  const handleChangeStatus = (
    event: ChangeEvent<HTMLInputElement>,
    data: Announcement
  ) => {
    updateAnnouncementFileStatus({
      announcement_id: data.id_m_web_announcement,
      status: data.file_status === "A" ? "I" : "A",
    });
  };

  const TableColumns: Column[] = [
    {
      key: "headline",
      label: "Headline",
      sortable: true,
      colSpan: 3,
      renderCell: (data) => (
        <Link href={data.url} sx={{ textDecoration: "none" }} target="_blank">
          <Box sx={{ color: "primary.main", fontWeight: "bold" }}>
            {data.headline}
          </Box>
        </Link>
      ),
    },
    {
      key: "date",
      label: "Date",
      sortable: true,
      renderCell: (data) => <Box>{data.createdate}</Box>,
    },
    {
      key: "type",
      label: "Type",
      sortable: true,
      renderCell: (data) => (
        <AnnouncementTypeCell
          categoryId={data.id_m_category_announcement}
          categories={categories}
        />
      ),
    },
    {
      key: "option",
      label: "Options",
      sortable: false,
      renderCell: (data) => (
        <Switch
          checked={data.file_status === "A"}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleChangeStatus(e, data as Announcement)
          }
        />
      ),
    },
  ];

  const categoryOptions = useMemo(() => {
    if (!categories) return [];
    return categories.map(({ name, id_m_category_announcement }) => ({
      label: name,
      value: id_m_category_announcement,
    }));
  }, [categories]);

  const tableData = useMemo(() => {
    if (!announcements) return [];
    return announcements.filter((item) => {
      if (!categories) return true;

      if (!category) return true;

      return item.id_m_category_announcement === category;
    });
  }, [announcements, categories, category]);

  const visibleKeys = TableColumns.map((col) => col.key);

  // Filter table rows by "search" input value
  const filteredTableData = useMemo(() => {
    if (query?.length < 3) return tableData;
    return tableData.filter((item) => {
      return visibleKeys.some(
        (k) =>
          (item as any)[k] &&
          ((item as any)[k] as any)
            .toString()
            .toLowerCase()
            .includes(query.toLowerCase())
      );
    });
  }, [query, tableData, visibleKeys]);

  useEffect(() => {
    // Reset table page to 0 when category changes
    setPage(0);
  }, [category]);

  useEffect(() => {
    // Reset table page to 0 when search query gets updated
    if (query.length >= 3) {
      setPage(0);
    }
  }, [query]);

  return (
    <Box className={classes.page}>
      <Box className={classes.pageHeader}>Announcements</Box>
      <Box className={classes.container}>
        <Box className={classes.tableBox}>
          <Section title="Announcement list">
            <Box className={classes.headerSection}>
              <Box>
                <Button variant="contained" onClick={openCreatePanel}>
                  New Announcement
                </Button>
              </Box>
              <Box className={classes.selectorsRow}>
                <Box className={classes.searchContainer}>
                  <SearchInput
                    label=""
                    placeholder="Search by..."
                    onChange={handleChangeQuery}
                  />
                </Box>
                <Box className={classes.selectorsContainer}>
                  <Box>
                    <Select
                      label="Announcement Type"
                      value={category}
                      onChange={handleChangeCategory}
                      options={categoryOptions}
                      width={240}
                      displayAll
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box>
              <Table
                columns={TableColumns}
                data={filteredTableData}
                currentPage={page}
                setCurrentPage={setPage}
                loading={isFetching}
              />
            </Box>
          </Section>
        </Box>
        {selectedItem ? (
          <EditAnnouncementPanel
            data={selectedItem}
            categories={categories}
            onClose={() => setSelected(undefined)}
          />
        ) : null}
        {createPanelVisible ? (
          <EditAnnouncementPanel
            categories={categories}
            onClose={closeCreatePanel}
          />
        ) : null}
      </Box>
    </Box>
  );
};

export default AnnouncementsPage;
