import { FC, useEffect, useState } from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import { useFormik } from "formik";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import * as yup from "yup";
import {
  useCreateIndexLabMutation,
  useUpdateIndexLabBgImgMutation,
  useUpdateIndexLabBrochureFileMutation,
  useUpdateIndexLabFactsheetMutation,
  useUpdateIndexLabInfoMutation,
  useUpdateIndexLabPIMMutation,
  useUpdateIndexLabPriceSeriesMutation,
} from "../../../redux/queries/indexLab";
import { AssetClass, FamiliesIndexes, IndexType } from "../../../types";
import { IndexLab } from "../../../types/indexLab";
import AssetClassIndexFamilySelect from "../../EditIndexPanel/EditIndexForm/AssetClassIndexFamilySelect";
import FileUploadInput from "../../FileUploadInput";
import TextInput from "../../TextInput";
import { useStyles } from "../styles";

interface Props {
  data?: IndexLab;
  assetClassOptions: AssetClass[];
  indexTypeOptions: IndexType[];
  families: FamiliesIndexes;
  onClose: () => void;
}

const validationSchema = yup.object({
  index_name: yup.string().required("Index name is required"),
  description: yup.string().required("Description is required"),
});

const EditIndexLabForm: FC<Props> = ({
  data,
  assetClassOptions,
  indexTypeOptions,
  families,
  onClose,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [updateInfo, { isError, isSuccess }] = useUpdateIndexLabInfoMutation();
  const [
    updateFactSheet,
    {
      isSuccess: isSuccessUpdateFactsheet,
      isError: isErrorUpdateFactsheet,
      isLoading: isLoadingUpdateFactsheet,
    },
  ] = useUpdateIndexLabFactsheetMutation();
  const [
    updatePriceSeries,
    {
      isSuccess: isSuccessUpdatePriceSeries,
      isError: isErrorUpdatePriceSeries,
      isLoading: isLoadingUpdatePriceSeries,
    },
  ] = useUpdateIndexLabPriceSeriesMutation();
  const [
    updatePIM,
    {
      isSuccess: isSuccessUpdatePIM,
      isError: isErrorUpdatePIM,
      isLoading: isLoadingUpdatePIM,
    },
  ] = useUpdateIndexLabPIMMutation();
  const [
    updateBgImg,
    {
      isSuccess: isSuccessUpdateBgImg,
      isError: isErrorUpdateBgImg,
      isLoading: isLoadingUpdateBgImg,
    },
  ] = useUpdateIndexLabBgImgMutation();
  const [
    updateBrochureFile,
    {
      isSuccess: isSuccessUpdateBrochureFile,
      isError: isErrorUpdateBrochureFile,
      isLoading: isLoadingUpdateBrochureFile,
    },
  ] = useUpdateIndexLabBrochureFileMutation();
  const [
    createIndexLab,
    {
      isSuccess: isSuccessCreate,
      isError: isErrorCreate,
      isLoading: isLoadingCreate,
    },
  ] = useCreateIndexLabMutation();

  const isCreateMode = !data?.index_lab_id;

  const initialData: IndexLab = !isCreateMode
    ? data
    : {
        index_lab_id: -1,
        index_name: "",
        description: "",
        background_image_url: "",
        status: "A",
        index_prototype: false,
        asset_class: assetClassOptions[0],
        index_type: indexTypeOptions[0],
      };

  const [localFactsheet, setLocalFactsheet] = useState<File>();
  const [localBgImg, setLocalBgImg] = useState<File>();
  const [localPriceSeries, setLocalPriceSeries] = useState<File>();
  const [localBrochureFile, setLocalBrochureFile] = useState<File>();
  const [localPIM, setLocalPIM] = useState<File>();
  const [localCategorization, setLocalCategorization] = useState<{
    assetClass: number;
    indexType: number;
  }>({
    assetClass: initialData.asset_class.id,
    indexType: initialData.index_type.id,
  });

  const { handleChange, handleSubmit, touched, errors, values } =
    useFormik<IndexLab>({
      initialValues: initialData,
      validationSchema: validationSchema,
      onSubmit: (values) => {
        createIndexLab({
          index_name: values.index_name,
          description: values.description,
          asset_id: localCategorization.assetClass,
          index_type_id: localCategorization.indexType,
          price_series_file: localPriceSeries,
          pim_file: localPIM,
          background_image: localBgImg,
          factsheet_file: localFactsheet,
          brochure_file: localBrochureFile,
        });
      },
      enableReinitialize: true,
    });

  const handleSaveInfo = (fieldName: string, value: string) => {
    updateInfo({
      id: initialData.index_lab_id,
      [fieldName]: value,
    });
  };

  const handleSaveAssetClassIndexType = (value: {
    assetClass: number;
    indexType: number;
  }) => {
    if (isCreateMode) {
      return setLocalCategorization({
        assetClass: value.assetClass,
        indexType: value.indexType,
      });
    }
    updateInfo({
      ...initialData,
      id: initialData.index_lab_id,
      asset_id: value.assetClass,
      index_type_id: value.indexType,
    });
  };

  const handleSaveFactsheet = () => {
    if (localFactsheet) {
      updateFactSheet({
        id: initialData.index_lab_id,
        file: localFactsheet,
      });
    }
  };

  const handleSavePriceSeries = () => {
    if (localPriceSeries) {
      updatePriceSeries({
        id: initialData.index_lab_id,
        file: localPriceSeries,
      });
    }
  };

  const handleSavePIM = () => {
    if (localPIM) {
      updatePIM({
        id: initialData.index_lab_id,
        file: localPIM,
      });
    }
  };

  const handleSaveBrochure = () => {
    if (localBrochureFile) {
      updateBrochureFile({
        id: initialData.index_lab_id,
        file: localBrochureFile,
      });
    }
  };

  const handleSaveBgImg = () => {
    if (localBgImg) {
      updateBgImg({
        id: initialData.index_lab_id,
        file: localBgImg,
      });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar({
        message: "Successfully updated index lab.",
        variant: "success",
        autoHideDuration: 3000,
      });
      onClose();
    }
  }, [enqueueSnackbar, isSuccess, onClose]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar({
        message: "Failed to update index lab.",
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }, [enqueueSnackbar, isError]);

  useEffect(() => {
    if (isSuccessUpdateFactsheet) {
      enqueueSnackbar({
        message: "Successfully updated factsheet.",
        variant: "success",
        autoHideDuration: 3000,
      });
      setLocalFactsheet(undefined);
      onClose();
    }
  }, [enqueueSnackbar, isSuccessUpdateFactsheet, onClose]);

  useEffect(() => {
    if (isErrorUpdateFactsheet) {
      enqueueSnackbar({
        message: "Failed to update factsheet.",
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }, [enqueueSnackbar, isErrorUpdateFactsheet]);

  useEffect(() => {
    if (isSuccessUpdatePriceSeries) {
      enqueueSnackbar({
        message: "Successfully updated price series.",
        variant: "success",
        autoHideDuration: 3000,
      });
      setLocalPriceSeries(undefined);
      onClose();
    }
  }, [enqueueSnackbar, isSuccessUpdatePriceSeries, onClose]);

  useEffect(() => {
    if (isErrorUpdatePriceSeries) {
      enqueueSnackbar({
        message: "Failed to update price series.",
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }, [enqueueSnackbar, isErrorUpdatePriceSeries]);

  useEffect(() => {
    if (isSuccessUpdatePIM) {
      enqueueSnackbar({
        message: "Successfully updated PIM.",
        variant: "success",
        autoHideDuration: 3000,
      });
      setLocalPIM(undefined);
      onClose();
    }
  }, [enqueueSnackbar, isSuccessUpdatePIM, onClose]);

  useEffect(() => {
    if (isErrorUpdatePIM) {
      enqueueSnackbar({
        message: "Failed to update PIM.",
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }, [enqueueSnackbar, isErrorUpdatePIM]);

  useEffect(() => {
    if (isSuccessUpdateBrochureFile) {
      enqueueSnackbar({
        message: "Successfully updated brochure file.",
        variant: "success",
        autoHideDuration: 3000,
      });
      setLocalBrochureFile(undefined);
      onClose();
    }
  }, [enqueueSnackbar, isSuccessUpdateBrochureFile, onClose]);

  useEffect(() => {
    if (isErrorUpdateBrochureFile) {
      enqueueSnackbar({
        message: "Failed to update brochure file.",
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }, [enqueueSnackbar, isErrorUpdateBrochureFile]);

  useEffect(() => {
    if (isSuccessUpdateBgImg) {
      enqueueSnackbar({
        message: "Successfully updated background image.",
        variant: "success",
        autoHideDuration: 3000,
      });
      setLocalBgImg(undefined);
      onClose();
    }
  }, [enqueueSnackbar, isSuccessUpdateBgImg, onClose]);

  useEffect(() => {
    if (isErrorUpdateBgImg) {
      enqueueSnackbar({
        message: "Failed to update background image.",
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }, [enqueueSnackbar, isErrorUpdateBgImg]);

  useEffect(() => {
    if (isSuccessCreate) {
      enqueueSnackbar({
        message: "Successfully created index lab.",
        variant: "success",
        autoHideDuration: 3000,
      });
      setLocalBgImg(undefined);
      onClose();
    }
  }, [enqueueSnackbar, isSuccessCreate, onClose]);

  useEffect(() => {
    if (isErrorCreate) {
      enqueueSnackbar({
        message: "Failed to create index lab.",
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }, [enqueueSnackbar, isErrorCreate]);

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <TextInput
          name="index_name"
          label="Index Name"
          value={values.index_name}
          error={touched.index_name && Boolean(errors.index_name)}
          helperText={touched.index_name && errors.index_name}
          fullWidth
          placeholder="Type index name..."
          onChange={handleChange}
          onSave={isCreateMode ? undefined : handleSaveInfo}
        />
        <TextInput
          name="description"
          label="Description"
          value={values.description}
          error={touched.description && Boolean(errors.description)}
          helperText={touched.description && errors.description}
          fullWidth
          placeholder="Type description..."
          onChange={handleChange}
          onSave={isCreateMode ? undefined : handleSaveInfo}
          multiline
          minRows={5}
          maxLength={1000}
        />
        <Box>
          <AssetClassIndexFamilySelect
            initialValues={{
              assetClass: initialData.asset_class.id,
              indexType: initialData.index_type.id,
            }}
            families={families}
            assetClasses={assetClassOptions}
            indexTypes={indexTypeOptions}
            onChange={handleSaveAssetClassIndexType}
          />
        </Box>
        <Box>
          <FileUploadInput
            label="Factsheet"
            filename={localFactsheet?.name ?? initialData.factsheet_url ?? ""}
            setFile={setLocalFactsheet}
            disabled={!localFactsheet}
            loading={isLoadingUpdateFactsheet}
            onSubmit={isCreateMode ? undefined : handleSaveFactsheet}
            acceptFileFormat="application/pdf"
            uploadDate={
              data?.factsheet_date
                ? DateTime.fromISO(data.factsheet_date).toFormat(
                    "LL/dd/yyyy 'at' hh:mm:ss"
                  )
                : ""
            }
          />
        </Box>
        <Box>
          <FileUploadInput
            label="Price Series"
            filename={
              localPriceSeries?.name ?? initialData.prices_series_url ?? ""
            }
            setFile={setLocalPriceSeries}
            disabled={!localPriceSeries}
            loading={isLoadingUpdatePriceSeries}
            onSubmit={isCreateMode ? undefined : handleSavePriceSeries}
            acceptFileFormat=".xlsx, .csv"
            uploadDate={
              data?.prices_series_date
                ? DateTime.fromISO(data.prices_series_date).toFormat(
                    "LL/dd/yyyy 'at' hh:mm:ss"
                  )
                : ""
            }
          />
        </Box>
        <Box>
          <FileUploadInput
            label="PIM"
            filename={localPIM?.name ?? initialData.pim_url ?? ""}
            setFile={setLocalPIM}
            disabled={!localPIM}
            loading={isLoadingUpdatePIM}
            onSubmit={isCreateMode ? undefined : handleSavePIM}
            acceptFileFormat="application/pdf"
            uploadDate={
              data?.pim_date
                ? DateTime.fromISO(data.pim_date).toFormat(
                    "LL/dd/yyyy 'at' hh:mm:ss"
                  )
                : ""
            }
          />
        </Box>
        <Box>
          <FileUploadInput
            label="Brochure File"
            filename={
              localBrochureFile?.name ?? initialData.index_brochure_file ?? ""
            }
            setFile={setLocalBrochureFile}
            disabled={!localBrochureFile}
            loading={isLoadingUpdateBrochureFile}
            onSubmit={isCreateMode ? undefined : handleSaveBrochure}
            acceptFileFormat="application/pdf"
            uploadDate={
              data?.index_brochure_date
                ? DateTime.fromISO(data.index_brochure_date).toFormat(
                    "LL/dd/yyyy 'at' hh:mm:ss"
                  )
                : ""
            }
          />
        </Box>
        <Box>
          <FileUploadInput
            label="Background Image"
            filename={
              localBgImg?.name ?? initialData.background_image_url ?? ""
            }
            setFile={setLocalBgImg}
            disabled={!localBgImg}
            loading={isLoadingUpdateBgImg}
            onSubmit={isCreateMode ? undefined : handleSaveBgImg}
            acceptFileFormat="image/*"
            uploadDate={
              data?.backgroud_image_date
                ? DateTime.fromISO(data.backgroud_image_date).toFormat(
                    "LL/dd/yyyy 'at' hh:mm:ss"
                  )
                : ""
            }
          />
        </Box>
        {isCreateMode ? (
          <Box pt={4} textAlign="right">
            <Button
              variant="outlined"
              onClick={onClose}
              sx={{ marginRight: 2 }}
            >
              Cancel
            </Button>

            <Button variant="contained" type="submit">
              {isLoadingCreate ? (
                <Box className={classes.submitLoaderWrapper}>
                  <CircularProgress size={20} color="inherit" />
                </Box>
              ) : (
                "Create Index Lab"
              )}
            </Button>
          </Box>
        ) : null}
      </form>
    </Box>
  );
};

export default EditIndexLabForm;
