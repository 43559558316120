import { FC } from "react";
import { Box } from "@mui/material";
import {
  IndexType,
  IndexListItem,
  FamiliesIndexes,
  AssetClass,
} from "../../../types";
import Section from "../../Section";
import EditIndexForm from "../EditIndexForm";
import EditSectionsConfig from "../EditSectionsConfig";
import UploadFilesForm from "../UploadFilesForm";

interface Props {
  data: IndexListItem;
  families?: FamiliesIndexes;
  assetClasses: AssetClass[];
  indexTypes: IndexType[];
}

const EditIndexFormContent: FC<Props> = ({
  data,
  families,
  assetClasses,
  indexTypes,
}) => {
  return (
    <>
      <Section title="Index information">
        <EditIndexForm
          initialData={data}
          families={families}
          assetClasses={assetClasses}
          indexTypes={indexTypes}
        />
      </Section>
      <Box mt={2}>
        <Section title="INDEX MATERIALS UPLOAD">
          <UploadFilesForm data={data} />
        </Section>
      </Box>

      <Box mt={2}>
        <Section title="SECTIONS CONFIGURATION">
          <EditSectionsConfig
            index_id={data.index_id}
            top10ConstituentsStatus={data.top_10_constituents_status}
            constituentsFileStatus={data.constituents_file_status}
            customButtonStatus={data.custom_button_status}
            customButtonName={data.custom_button_name}
            customButtonUrl={data.custom_button_url}
            fileShouldBeDownloadable={data.file_should_be_downloadable}
          />
        </Section>
      </Box>
    </>
  );
};

export default EditIndexFormContent;
