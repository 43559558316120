import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  textField: {
    "& .MuiFormLabel-root.MuiInputLabel-root": {
      color: "#235CFF",
      fontWeight: 700,
      fontSize: theme.spacing(2),
    },
  },
  extraContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: theme.spacing(1),
  },
  actionsContainer: {
    display: "flex",
    alignItems: "center",
  },
  charsLeftContainer: {
    fontSize: theme.spacing(1.5),
  },
  charsLeft: {
    display: "inline-block",
    backgroundColor: "#88B5FF",
    minWidth: theme.spacing(6),
    color: "#fff",
    fontWeight: 700,
    borderRadius: theme.spacing(0.5),
    textAlign: "center",
  },
  actionIcon: {
    cursor: "pointer",
  },
}));
