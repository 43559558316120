import { FC } from "react";
import { Box } from "@mui/material";
import { AnnouncementCategory } from "../../types";

interface Props {
  categoryId: number;
  categories?: AnnouncementCategory[];
}

const COLOR_MAP = [
  "#DFDFDF",
  "#0031D9",
  "#25246D",
  "#1684FF",
  "#535BB8",
  "#88B5FF",
];

const AnnouncementTypeCell: FC<Props> = ({ categories, categoryId }) => {
  const found = categories?.find(
    (c) => c.id_m_category_announcement === categoryId
  );

  return (
    <Box
      sx={{
        backgroundColor: COLOR_MAP[found?.id_m_category_announcement ?? 0],
        color: "#fff",
        textAlign: "center",
        padding: "0.5rem 1.5rem",
        borderRadius: "0.25rem",
        textTransform: "uppercase",
        fontSize: "0.5625rem",
        fontWeight: 600,
        width: "12rem",
      }}
    >
      {found?.name ?? "Unknown"}
    </Box>
  );
};

export default AnnouncementTypeCell;
