import { useMemo, useState } from "react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Box } from "@mui/material";
import EditGovernance from "../../components/EditGovernance";
import Section from "../../components/Section";
import Table, { Column } from "../../components/Table";
import { useFetchGovernancesQuery } from "../../redux/queries/governances";
import { useStyles } from "./styles";

const GovernancePage = () => {
  const classes = useStyles();
  const { data, isFetching  } = useFetchGovernancesQuery({});
  const [selected, setSelected] = useState<number>();
  const [page, setPage] = useState<number>(-1);
  
  const tableData = useMemo(() => {
    if (!data) return [];
    return data
  }, [data]);

  const selectedGovernance = useMemo(
    () => data?.find((item) => item.id_m_governance === selected),
    [data, selected]
  );

  console.info(selectedGovernance)

  const handleSelect = (val: number) => {
    setSelected(val);
  };
    
  const TableColumns: Column[] = [
    {
      key: "title",
      label: "Title",
      sortable: true,
      colSpan: 8,
      width: "90%",
      renderCell: (data) => (
        <Box sx={{ color: "primary.main", fontWeight: "bold" }}>
          {data.title}
        </Box>
      ),
    },
    {
      key: "option",
      label: "Options",
      sortable: false,
      renderCell: (data) => (
        <Box
          sx={{ cursor: "pointer" }}
          onClick={() => handleSelect(data.id_m_governance)}
        >
          <EditOutlinedIcon />
        </Box>
      ),
    },
  ];



  return (
    <Box className={classes.page}>
      <Box className={classes.pageHeader}>Governance</Box>
      <Box className={classes.container}>
        <Box className={classes.selectorsRow}>
          <Box className={classes.tableBox}>
            <Section title="Governance">
              <Box className={classes.table}>
                <Table
                  columns={TableColumns}
                  data={tableData}
                  defaultOrderBy="name"
                  currentPage={page}
                  setCurrentPage={setPage}
                  loading={isFetching }
                  />
              </Box>
            </Section>
          </Box>
          <Box className={classes.materialUploadBox}>
            <EditGovernance
              data={selectedGovernance}
              onClose={() => setSelected(undefined)}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default GovernancePage;