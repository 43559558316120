import { Box } from "@mui/material";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import MainLayout from "./layouts/Main";
import SimpleLayout from "./layouts/Simple";
import AnnouncementsPage from "./pages/Announcements";
import CryptocurrenciesPage from "./pages/Cryptocurrencies";
import GovernancePage from "./pages/Governance";
import IndexLabPage from "./pages/IndexLab";
import IndexManagementPage from "./pages/IndexManagement";
import LoginPage from "./pages/Login";
import NewsPage from "./pages/News";
import "./App.css";

function App() {
  return (
    <BrowserRouter>
      <Box>
        <Box className="header">
          <Routes>
            <Route path="/*" element={<Header />} />
          </Routes>
        </Box>

        <Box className="body">
          <Routes>
            <Route path="/" element={<MainLayout />}>
              <Route index element={<IndexManagementPage />} />
              <Route path="announcements" element={<AnnouncementsPage />} />
              <Route path="news" element={<NewsPage />} />
              <Route path="index-lab" element={<IndexLabPage />} />
              <Route path="governance" element={<GovernancePage />} />
              <Route
                path="cryptocurrencies"
                element={<CryptocurrenciesPage />}
              />
            </Route>
            <Route path="/auth" element={<SimpleLayout />}>
              <Route path="login" element={<LoginPage />} />
            </Route>
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
        </Box>

        <Box className="footer">
          <Routes>
            <Route path="/*" element={<Footer />} />
          </Routes>
        </Box>
      </Box>
    </BrowserRouter>
  );
}

export default App;
