import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL ?? "/",
  credentials: "same-origin",
  prepareHeaders: (headers: any, { getState }: any) => {
    const authToken = getState()?.auth?.token ?? "";
    headers.set("authorization", `Bearer ${authToken}`);
    return headers;
  },
});
