import { FC, useEffect, useMemo, useState } from "react";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseIcon from "@mui/icons-material/Close";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Box, SelectChangeEvent } from "@mui/material";
import { AssetClass, FamiliesIndexes, IndexType } from "../../../../types";
import Select from "../../../Select";
import { useStyles } from "../../styles";

interface Props {
  initialValues: {
    assetClass: number;
    indexType: number;
  };
  families?: FamiliesIndexes;
  assetClasses: AssetClass[];
  indexTypes: IndexType[];
  onChange: (value: { assetClass: number; indexType: number }) => void;
}

const AssetClassIndexTypeSelect: FC<Props> = ({
  initialValues,
  families,
  assetClasses,
  indexTypes,
  onChange,
}) => {
  const classes = useStyles();

  const [assetClass, setAssetClass] = useState<number>(
    initialValues.assetClass
  );
  const [indexType, setIndexType] = useState<number>(initialValues.indexType);
  const [stateDisabled, setStateDisabled] = useState<boolean>(true);

  const toggleDisabled = () => setStateDisabled(!stateDisabled);

  const assetClassOptions = useMemo(() => {
    if (!families) return [];
    return Object.keys(families).map((k) => {
      const found = assetClasses.find((ac) => ac.name === k);
      return {
        label: k,
        value: found?.id,
      };
    });
  }, [families, assetClasses]);

  const indexTypeOptions = useMemo(() => {
    const currentAssetName = assetClasses.find(
      (ac) => ac.id === assetClass
    )?.name;

    if (!families || !currentAssetName || !families[currentAssetName]) {
      return [];
    }

    return Object.keys(families[currentAssetName]).map((k) => {
      const found = indexTypes.find((it) => it.name === k);

      return {
        label: k,
        value: found?.id,
      };
    });
  }, [assetClass, assetClasses, families, indexTypes]);

  const handleChangeAssetClass = (event: SelectChangeEvent<any>) => {
    setAssetClass(event.target.value);
  };

  const handleChangeIndexType = (event: SelectChangeEvent<any>) => {
    setIndexType(event.target.value);
  };

  const onSave = () => {
    if (stateDisabled) return;
    if (assetClass >= 0 && indexType >= 0) {
      onChange({
        assetClass,
        indexType,
      });
      setStateDisabled(true);
    }
  };

  useEffect(() => {
    setAssetClass(initialValues.assetClass);
  }, [initialValues.assetClass]);

  useEffect(() => {
    setIndexType(initialValues.indexType);
  }, [initialValues.indexType]);

  return (
    <Box>
      <Box className={classes.primaryLabel} mb={2}>
        Categorization
      </Box>
      <Box className={classes.selectorsRow}>
        <Select
          label="Asset Class"
          value={assetClass}
          onChange={handleChangeAssetClass}
          options={assetClassOptions}
          width={200}
          disabled={stateDisabled}
        />
        <Box className={stateDisabled ? classes.textDisabled : ""}>&</Box>
        <Select
          label="Index Type"
          value={indexType}
          onChange={handleChangeIndexType}
          options={indexTypeOptions}
          width={200}
          disabled={!assetClass || stateDisabled}
        />
      </Box>
      <Box className={classes.actionsContainer}>
        <Box className={classes.actionIcon} onClick={toggleDisabled}>
          {stateDisabled ? (
            <EditOutlinedIcon fontSize="small" sx={{ marginRight: 1 }} />
          ) : (
            <CloseIcon fontSize="small" sx={{ marginRight: 1 }} />
          )}
        </Box>
        <Box className={classes.actionIcon} onClick={onSave}>
          <CheckOutlinedIcon fontSize="small" />
        </Box>
      </Box>
    </Box>
  );
};

export default AssetClassIndexTypeSelect;
