import { createApi } from "@reduxjs/toolkit/query/react";
import { ApiResponseData, WebStatus } from "../../types";
import { IndexLab } from "../../types/indexLab";
import { RTK_SLICE_API_LIST } from "../../utils/constants";
import { convertObjToFormData, getTags } from "../../utils/rtk-query";
import { baseQuery } from "./base";

const reducerPath = "index-lab";

export const indexLabApi = createApi({
  reducerPath,
  baseQuery: baseQuery,
  tagTypes: [reducerPath],
  endpoints: (builder) => ({
    fetchIndexLabs: builder.query({
      query: () => "/v2/index-lab",
      transformResponse: (responseData: ApiResponseData<IndexLab[]>) =>
        responseData?.data || [],
      providesTags: (result) => getTags(reducerPath, "index_lab_id", result),
    }),
    createIndexLab: builder.mutation({
      query: (data: {
        index_name: string;
        description: string;
        asset_id: number;
        index_type_id: number;
        price_series_file?: File;
        pim_file?: File;
        background_image?: File;
        factsheet_file?: File;
        brochure_file?: File;
      }) => ({
        url: `/v2/index-lab`,
        method: "POST",
        body: convertObjToFormData(data),
      }),
      invalidatesTags: () => [{ type: reducerPath, id: RTK_SLICE_API_LIST }],
    }),
    updateIndexLabInfo: builder.mutation({
      query: ({
        id,
        index_name,
        status,
        description,
        asset_id,
        index_type_id,
      }: {
        id: number;
        index_name?: string;
        status?: WebStatus;
        description?: string;
        asset_id?: number;
        index_type_id?: number;
      }) => ({
        url: `/v2/index-lab/${id}`,
        method: "PUT",
        body: {
          index_name,
          status,
          description,
          asset_id,
          index_type_id,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: reducerPath, id: arg.id },
      ],
    }),
    updateIndexLabFactsheet: builder.mutation({
      query: ({ id, file }: { id: number; file: File }) => ({
        url: `/v2/index-lab/${id}/factsheet-file`,
        method: "PUT",
        body: convertObjToFormData({ file }),
      }),
      invalidatesTags: (result, error, arg) => [
        { type: reducerPath, id: arg.id },
      ],
    }),
    updateIndexLabPriceSeries: builder.mutation({
      query: ({ id, file }: { id: number; file: File }) => ({
        url: `/v2/index-lab/${id}/price-series`,
        method: "PUT",
        body: convertObjToFormData({ file }),
      }),
      invalidatesTags: (result, error, arg) => [
        { type: reducerPath, id: arg.id },
      ],
    }),
    updateIndexLabPIM: builder.mutation({
      query: ({ id, file }: { id: number; file: File }) => ({
        url: `/v2/index-lab/${id}/pim-file`,
        method: "PUT",
        body: convertObjToFormData({ file }),
      }),
      invalidatesTags: (result, error, arg) => [
        { type: reducerPath, id: arg.id },
      ],
    }),
    updateIndexLabBgImg: builder.mutation({
      query: ({ id, file }: { id: number; file: File }) => ({
        url: `/v2/index-lab/${id}/background-image`,
        method: "PUT",
        body: convertObjToFormData({ file }),
      }),
      invalidatesTags: (result, error, arg) => [
        { type: reducerPath, id: arg.id },
      ],
    }),
    updateIndexLabBrochureFile: builder.mutation({
      query: ({ id, file }: { id: number; file: File }) => ({
        url: `/v2/index-lab/${id}/brochure-file`,
        method: "PUT",
        body: convertObjToFormData({ file }),
      }),
      invalidatesTags: (result, error, arg) => [
        { type: reducerPath, id: arg.id },
      ],
    }),
    updateIndexLabPrototype: builder.mutation({
      query: ({ id }: { id: number }) => ({
        url: `/v2/index-lab/${id}/index-prototype`,
        method: "PUT",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: reducerPath, id: arg.id },
      ],
    }),
  }),
});

export const {
  useFetchIndexLabsQuery,
  useCreateIndexLabMutation,
  useUpdateIndexLabFactsheetMutation,
  useUpdateIndexLabInfoMutation,
  useUpdateIndexLabBgImgMutation,
  useUpdateIndexLabBrochureFileMutation,
  useUpdateIndexLabPIMMutation,
  useUpdateIndexLabPriceSeriesMutation,
  useUpdateIndexLabPrototypeMutation,
} = indexLabApi;
