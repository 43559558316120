import { createApi } from "@reduxjs/toolkit/query/react";
import { ApiResponseData, Governance } from "../../types";
import { RTK_SLICE_API_LIST } from "../../utils/constants";
import { convertObjToFormData, getTags } from "../../utils/rtk-query";
import { baseQuery } from "./base";

const reducerPath = "governances";

export const governancesApi = createApi({
  reducerPath,
  baseQuery: baseQuery,
  tagTypes: [reducerPath],
  endpoints: (builder) => ({
    FetchGovernances: builder.query({
      query: () => "/v2/governance",
      transformResponse: (responseData: ApiResponseData<Governance[]>) =>
        responseData?.data || [],
        providesTags: (result) => getTags(reducerPath, "id_m_governance", result),
    }),
    createGovernance: builder.mutation({
      query: (formData: FormData) => ({
        url: `/v2/governance`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: () => [{ type: reducerPath, id: RTK_SLICE_API_LIST }],
    }),
    updateGovernance: builder.mutation({
      query: ({
        id_m_governance,
        title,
      }: {
        id_m_governance: string;
        title: string
      }) => ({
        url: `/v2/governance/${id_m_governance}`,
        method: "PUT",
        body: { title: title, "status": "A"}
      }),
      invalidatesTags: (result, error, arg) => [{ type: reducerPath, id: arg.id_m_governance }],

    }),
    updateGovernanceDownloadFile: builder.mutation({
      query: ({
        id_m_governance,
        file,
      }: {
        id_m_governance: string;
        file: File;
      }) => ({
        url: `/v2/governance/${id_m_governance}/file`,
        method: "PUT",
        body: convertObjToFormData({ file }),
      }),
      invalidatesTags: (result, error, arg) => [{ type: reducerPath, id: arg.id_m_governance }],
    }),
  }),
});

export const {
    useFetchGovernancesQuery,
    useUpdateGovernanceMutation,
    useCreateGovernanceMutation,
    useUpdateGovernanceDownloadFileMutation,
  } = governancesApi;