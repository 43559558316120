import { FC, useEffect } from "react";
import { Box } from "@mui/material";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import * as yup from "yup";
import { useUpdateIndexCategoryMutation, useUpdateIndexInformationMutation } from "../../../redux/queries/indexes";
import {
  AssetClass,
  FamiliesIndexes,
  IndexListItem,
  IndexType,
} from "../../../types";
import TextInput from "../../TextInput";
import AssetClassIndexFamilySelect from "./AssetClassIndexFamilySelect";
import StatusSelect from "./StatusSelect";

interface Props {
  initialData: IndexListItem;
  families?: FamiliesIndexes;
  assetClasses: AssetClass[];
  indexTypes: IndexType[];
}

const validationSchema = yup.object({
  name: yup.string().required("Name is required"),
  description: yup
    .string()
    .max(1000, "Description is too long.")
    .required("Description is required"),
});

const EditIndexForm: FC<Props> = ({
  initialData,
  families,
  assetClasses,
  indexTypes,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { handleChange, touched, errors, values } = useFormik<IndexListItem>({
    initialValues: initialData,
    validationSchema: validationSchema,
    onSubmit: (values) => {},
    enableReinitialize: true,
  });

  const [updateIndexInformation, { isError }] =
    useUpdateIndexInformationMutation();
  const [updateIndexCategory, { isError: isErrorCategory, }] =
    useUpdateIndexCategoryMutation();
    

  const handleSaveInfo = (fieldName: string, value: string) => {
    updateIndexInformation({
      index_id: initialData.index_id,
      [fieldName]: value,
    });
  };

  const handleSaveCategorization = (value: {
    assetClass: number;
    indexType: number;
  }) => {
    updateIndexCategory({
      index_id: initialData.index_id,
      ...value,
      asset_class: value.assetClass,
      index_type: value.indexType
    });
  };

  useEffect(() => {
    if (isError) {
      enqueueSnackbar({
        message: "Failed to update index information.",
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }, [enqueueSnackbar, isError]);

  useEffect(() => {
    if (isErrorCategory) {
      enqueueSnackbar({
        message: "Failed to update index category.",
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }, [enqueueSnackbar, isErrorCategory]);

  return (
    <Box>
      <form>
        <TextInput
          name="name"
          label="Index Name"
          value={values.name}
          error={touched.name && Boolean(errors.name)}
          helperText={touched.name && errors.name}
          fullWidth
          onChange={handleChange}
          onSave={handleSaveInfo}
        />
        <TextInput
          name="ticker"
          label="Ticker"
          value={values.ticker}
          disabled
          fullWidth
        />
        <TextInput
          name="description"
          label="Index Description"
          value={values.description}
          error={touched.description && Boolean(errors.description)}
          helperText={touched.description && errors.description}
          fullWidth
          multiline
          minRows={5}
          maxLength={1000}
          onChange={handleChange}
          onSave={handleSaveInfo}
        />

        <AssetClassIndexFamilySelect
          initialValues={{
            assetClass: initialData.asset_class,
            indexType: initialData.index_type,
          }}
          families={families}
          assetClasses={assetClasses}
          indexTypes={indexTypes}
          onChange={handleSaveCategorization}
        />
        <StatusSelect data={initialData} />
      </form>
    </Box>
  );
};

export default EditIndexForm;
