import { FC } from "react";
import { Box } from "@mui/material";
import { Governance } from "../../types";
import Section from "../Section";
import EditNewsForm from "./EditGovernanceForm";
import { useStyles } from "./styles";

interface Props {
  data?: Governance;
  onClose: () => void;
}

const EditGovernance: FC<Props> = ({ data, onClose }) => {
  const classes = useStyles();

  const sectionTitle = data?.id_m_governance
    ? "Material Upload"
    : "Material Upload";

    return (
      <Box className={classes.editBox} pl={2}>
        <Section title={sectionTitle}>
          <EditNewsForm
            data={data}
            onClose={onClose}
          />
        </Section>
      </Box>
    );
};

export default EditGovernance;
