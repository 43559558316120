import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { pageStyles } from "../../theme";

export const useStyles = makeStyles((theme: Theme) => ({
  ...pageStyles(theme),
  root: {},
  container: {
    display: "flex",
    flex: 1,
    overflow: "auto",
  },
  headerSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  tableBox: {
    flex: 1,
  },
  selectorsRow: {
    display: "flex",
    flexDirection: "row-reverse",
    margin: "2.5rem 0 1.5rem",
  },
  searchContainer: {
    paddingLeft: "2rem",
  },
  selectorsContainer: {
    display: "flex",
  },
  selectContainer: {
    paddingLeft: "2rem",
  },
}));
