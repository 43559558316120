import { FC, ReactNode, useMemo } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select as SelectDefault,
  SelectChangeEvent,
  SelectProps,
} from "@mui/material";

export interface Option {
  label: string;
  value: any;
}

interface Props extends SelectProps {
  label?: string;
  value?: string | number;
  options: Option[];
  width?: number | string;
  disableUnderline?: boolean;
  displayEmpty?: boolean;
  displayAll?: boolean;
  noPaddingBottom?: boolean;
}

const ALL_VALUE = "--all--";

const Select: FC<Props> = ({
  label,
  value,
  options,
  width = 120,
  variant = "default",
  disableUnderline = false,
  onChange,
  displayEmpty,
  displayAll,
  noPaddingBottom = false,
  ...restProps
}) => {
  const currentValue = useMemo(
    () => (displayAll && value === "" ? ALL_VALUE : value),
    [displayAll, value]
  );

  const handleChange = (e: SelectChangeEvent<any>, child: ReactNode) => {
    if (displayAll && e.target.value === ALL_VALUE) {
      e.target.value = "";
    }
    onChange?.(e, child);
  };

  return (
    <Box
      sx={{
        width,
        backgroundColor: variant === "filled" ? "#fff" : "transparent",
        paddingBottom: noPaddingBottom ? "0" : "1rem",
        "& .MuiInput-underline": {
          paddingLeft: "1.25rem",
          paddingRight: "1.25rem",
        },
        "& .MuiSelect-icon": {
          right: "0.75rem",
        },
      }}
    >
      <FormControl variant="standard" sx={{ width: "100%" }}>
        <InputLabel id={label}>{label}</InputLabel>
        <SelectDefault
          labelId={label}
          value={currentValue}
          onChange={handleChange}
          label={label}
          disableUnderline={disableUnderline}
          displayEmpty
          MenuProps={{
            sx: {
              "& .MuiMenu-list": {
                backgroundColor: "#fff",
              },
            },
          }}
          {...restProps}
        >
          {displayAll && (
            <MenuItem
              key="all"
              value={ALL_VALUE}
              sx={{
                backgroundColor: "#fff",
                "&:hover": { backgroundColor: "#1684FF30" },
              }}
            >
              All
            </MenuItem>
          )}
          {options.map(({ label, value }, idx) => (
            <MenuItem
              key={label ?? idx}
              value={value}
              disabled={!value || value < 0}
              sx={{
                backgroundColor: "#fff",
                "&:hover": { backgroundColor: "#1684FF30" },
              }}
            >
              {label}
            </MenuItem>
          ))}
        </SelectDefault>
      </FormControl>
    </Box>
  );
};

export default Select;
