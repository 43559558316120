import { FC } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Drawer } from "@mui/material";
import { useIsXlDesktopView } from "../../hooks/useWindowSize";
import {
  AssetClass,
  FamiliesIndexes,
  IndexListItem,
  IndexType,
} from "../../types";
import EditIndexFormContent from "./EditIndexFormContent";
import { useStyles } from "./styles";

interface Props {
  data: IndexListItem;
  families?: FamiliesIndexes;
  assetClasses: AssetClass[];
  indexTypes: IndexType[];
  onClose: () => void;
}

const EditIndexPanel: FC<Props> = ({
  data,
  families,
  assetClasses,
  indexTypes,
  onClose,
}) => {
  const classes = useStyles();
  const inXlDesktopView = useIsXlDesktopView();

  if (inXlDesktopView) {
    return (
      <Box className={classes.editBox} pl={2}>
        <Box className={classes.closeContainer}>
          <Box className={classes.closeIcon} onClick={onClose}>
            <CloseIcon />
          </Box>
        </Box>
        <EditIndexFormContent
          data={data}
          families={families}
          assetClasses={assetClasses}
          indexTypes={indexTypes}
        />
      </Box>
    );
  }

  return (
    <Drawer anchor="right" open onClose={onClose}>
      <Box p={2}>
        <EditIndexFormContent
          data={data}
          families={families}
          assetClasses={assetClasses}
          indexTypes={indexTypes}
        />
      </Box>
    </Drawer>
  );
};

export default EditIndexPanel;
