import { createApi } from "@reduxjs/toolkit/query/react";
import {
  ApiResponseData,
  Announcement,
  AnnouncementCategory,
} from "../../types";
import { RTK_SLICE_API_LIST } from "../../utils/constants";
import { convertObjToFormData, getTags } from "../../utils/rtk-query";
import { baseQuery } from "./base";

const reducerPath = "announcements";

export const announcementsApi = createApi({
  reducerPath,
  baseQuery: baseQuery,
  tagTypes: [reducerPath],
  endpoints: (builder) => ({
    fetchAnnouncements: builder.query({
      query: () => "/v2/announcement",
      transformResponse: (responseData: ApiResponseData<Announcement[]>) =>
        responseData?.data || [],
      providesTags: (result) =>
        getTags(reducerPath, "id_m_web_announcement", result),
    }),
    fetchAnnouncementCategories: builder.query({
      query: () => "/v2/announcement/categories",
      transformResponse: (
        responseData: ApiResponseData<AnnouncementCategory[]>
      ) => responseData?.data || [],
    }),
    createAnnouncement: builder.mutation({
      query: (formData: FormData) => ({
        url: `/v2/announcement`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: () => [{ type: reducerPath, id: RTK_SLICE_API_LIST }],
    }),
    updateAnnouncementFileStatus: builder.mutation({
      query: (params: { announcement_id: number; status: string }) => ({
        url: `/v2/announcement/file/status`,
        method: "PUT",
        body: convertObjToFormData(params),
      }),
      invalidatesTags: (result, error, arg) => [
        { type: reducerPath, id: arg.announcement_id },
      ],
    }),
  }),
});

export const {
  useFetchAnnouncementsQuery,
  useFetchAnnouncementCategoriesQuery,
  useCreateAnnouncementMutation,
  useUpdateAnnouncementFileStatusMutation,
} = announcementsApi;
