import { FC, useMemo } from "react";
import { Box } from "@mui/material";
import { useFetchIndexFilesQuery } from "../../../redux/queries/indexFiles";
import { IndexListItem } from "../../../types";
import { INCLUDED_FILE_TYPES } from "../../../utils/constants";
import IndexFileInput from "./IndexFileInput";

interface Props {
  data: IndexListItem;
}

const UploadFilesForm: FC<Props> = ({ data }) => {
  const { data: indexFiles } = useFetchIndexFilesQuery({
    indexSymbol: data.ticker,
  });

  const filteredList = useMemo(() => {
    const files = [...(indexFiles ?? [])].sort(
      (a, b) => b.id_m_index_file - a.id_m_index_file
    );

    return INCLUDED_FILE_TYPES.map((fileType) => {
      const found = files.find((file) => file.type === fileType);
      return (
        found ?? {
          id_m_index_file: -1,
          key: "-",
          type: fileType,
          url: "",
          createdate: "",
          status: undefined,
        }
      );
    });
  }, [indexFiles]);

  return (
    <Box py={2}>
      {filteredList.map((file) => (
        <IndexFileInput
          key={`${file.id_m_index_file}-${file.type}`}
          indexId={data.index_id}
          fileId={file.id_m_index_file}
          status={file.status}
          fileKey={file.key}
          type={file.type}
          createDate={file.createdate}
        />
      ))}
    </Box>
  );
};

export default UploadFilesForm;
