import { FC } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Drawer } from "@mui/material";
import { useIsXlDesktopView } from "../../hooks/useWindowSize";
import { AssetClass, FamiliesIndexes, IndexType } from "../../types";
import { IndexLab } from "../../types/indexLab";
import Section from "../Section";
import EditIndexLabForm from "./EditIndexLabForm";
import { useStyles } from "./styles";

interface Props {
  data?: IndexLab;
  assetClasses: AssetClass[];
  indexTypes: IndexType[];
  families: FamiliesIndexes;
  onClose: () => void;
}

const EditIndexLabPanel: FC<Props> = ({
  data,
  assetClasses,
  indexTypes,
  families,
  onClose,
}) => {
  const classes = useStyles();
  const inXlDesktopView = useIsXlDesktopView();

  const sectionTitle = data?.index_lab_id
    ? "Index Lab Information"
    : "New Index Lab Information";

  if (inXlDesktopView) {
    return (
      <Box className={classes.editBox} pl={2}>
        <Box className={classes.closeContainer}>
          <Box className={classes.closeIcon} onClick={onClose}>
            <CloseIcon />
          </Box>
        </Box>
        <Section title={sectionTitle}>
          <EditIndexLabForm
            data={data}
            assetClassOptions={assetClasses}
            indexTypeOptions={indexTypes}
            families={families}
            onClose={onClose}
          />
        </Section>
      </Box>
    );
  }

  return (
    <Drawer anchor="right" open onClose={onClose}>
      <Box p={2}>
        <EditIndexLabForm
          data={data}
          assetClassOptions={assetClasses}
          indexTypeOptions={indexTypes}
          families={families}
          onClose={onClose}
        />
      </Box>
    </Drawer>
  );
};

export default EditIndexLabPanel;
