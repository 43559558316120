import { FC, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  SelectChangeEvent,
} from "@mui/material";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import * as yup from "yup";
import {
  useCreateNewsMutation,
  useFetchNewsCategorizationQuery,
  useUpdateDownloadFileMutation,
  useUpdateNewsFileMutation,
  useUpdateNewsMutation,
} from "../../../redux/queries/news";
import { News, NewsCategorization } from "../../../types";
import DatePicker from "../../DatePicker";
import FileUploadInput from "../../FileUploadInput";
import Select from "../../Select";
import TextInput from "../../TextInput";
import { useStyles } from "../styles";

interface Props {
  data?: News;
  categorizations?: NewsCategorization[];
  onClose: () => void;
}

const validationSchema = yup.object({
  title: yup.string().required("Index name is required"),
});

const EditIndexLabForm: FC<Props> = ({ data, categorizations, onClose }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { data: categorizationData } = useFetchNewsCategorizationQuery({});
  const [updateInfo, { isError, isSuccess }] = useUpdateNewsMutation();
  const [
    updateNewsFile,
    {
      isSuccess: isSuccessUpdateNewsFile,
      isError: isErrorUpdateNewsFile,
      isLoading: isLoadingUpdateNewsFile,
    },
  ] = useUpdateNewsFileMutation();
  const [
    updateDownloadFile,
    {
      isSuccess: isSuccessUpdateDownloadFile,
      isError: isErrorUpdateDownloadFile,
      isLoading: isLoadingUpdateDownloadFile,
    },
  ] = useUpdateDownloadFileMutation();
  const [
    createNews,
    {
      isSuccess: isSuccessCreate,
      isLoading: isLoadingCreate,
      isError: isErrorCreate,
    },
  ] = useCreateNewsMutation();

  const isCreateMode = !data?.id_m_web_news;

  const initialData: News = !isCreateMode
    ? data
    : {
        id_m_web_news: -1,
        title: "",
        link_media: "",
        preview_image: "",
        status: "A",
        createdate: "",
        download_file: "",
      };

  const [localPreviewImg, setLocalPreviewImg] = useState<File>();
  const [localDownloadFile, setLocalDownloadFile] = useState<File>();
  const [localCategorization, setLocalCategorization] = useState<number>();

  const { handleChange, handleSubmit, setFieldValue, touched, errors, values } =
    useFormik<News>({
      initialValues: initialData,
      validationSchema: validationSchema,
      onSubmit: (values) => {
        if (localCategorization) {
          createNews({
            title: values.title,
            categorization_id: localCategorization.toString(),
            link_media: values.link_media,
            preview_image: localPreviewImg,
            download_file: localDownloadFile,
          });
        }
      },
      enableReinitialize: true,
    });

  const handleSaveInfo = (fieldName: string, value: string) => {
    updateInfo({
      id_m_web_news: initialData.id_m_web_news,
      [fieldName]: value,
    });
  };

  const handleChangeDate = (e: any) => {
    if (isCreateMode) {
      return setFieldValue("createdate", e.$d.toISOString());
    }
    updateInfo({
      id_m_web_news: initialData.id_m_web_news,
      createdate: e.$d.toISOString(),
    });
  };

  const handleChangeCategory = (e: SelectChangeEvent<any>) => {
    if (isCreateMode) {
      return setLocalCategorization(e.target.value);
    }
    updateInfo({
      id_m_web_news: initialData.id_m_web_news,
      categorization_id: e.target.value,
    });
  };

  const handleSaveNewsFile = () => {
    if (localPreviewImg) {
      updateNewsFile({
        id_m_web_news: initialData.id_m_web_news,
        file: localPreviewImg,
      });
    }
  };

  const handleSaveDownloadFile = () => {
    if (localDownloadFile) {
      updateDownloadFile({
        id_m_web_news: initialData.id_m_web_news,
        file: localDownloadFile,
      });
    }
  };

  const categorizationOptions = useMemo(() => {
    return (
      categorizationData?.map(({ categorization_id, name }) => ({
        label: name,
        value: categorization_id,
      })) ?? []
    );
  }, [categorizationData]);

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar({
        message: "Successfully updated news.",
        variant: "success",
        autoHideDuration: 3000,
      });
    }
  }, [enqueueSnackbar, isSuccess]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar({
        message: "Failed to update news.",
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }, [enqueueSnackbar, isError]);

  useEffect(() => {
    if (isSuccessUpdateNewsFile) {
      enqueueSnackbar({
        message: "Successfully updated preview image.",
        variant: "success",
        autoHideDuration: 3000,
      });
      setLocalPreviewImg(undefined);
    }
  }, [enqueueSnackbar, isSuccessUpdateNewsFile]);

  useEffect(() => {
    if (isErrorUpdateNewsFile) {
      enqueueSnackbar({
        message: "Failed to update preview image.",
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }, [enqueueSnackbar, isErrorUpdateNewsFile]);

  useEffect(() => {
    if (isSuccessUpdateDownloadFile) {
      enqueueSnackbar({
        message: "Successfully updated download file.",
        variant: "success",
        autoHideDuration: 3000,
      });
      setLocalDownloadFile(undefined);
    }
  }, [enqueueSnackbar, isSuccessUpdateDownloadFile]);

  useEffect(() => {
    if (isErrorUpdateDownloadFile) {
      enqueueSnackbar({
        message: "Failed to update download file.",
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }, [enqueueSnackbar, isErrorUpdateDownloadFile]);

  useEffect(() => {
    if (isSuccessCreate) {
      enqueueSnackbar({
        message: "Successfully created news.",
        variant: "success",
        autoHideDuration: 3000,
      });
      onClose();
    }
  }, [enqueueSnackbar, isSuccessCreate, onClose]);

  useEffect(() => {
    if (isErrorCreate) {
      enqueueSnackbar({
        message: "Failed to create news.",
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }, [enqueueSnackbar, isErrorCreate]);

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <TextInput
          name="title"
          label="Title"
          value={values.title}
          error={touched.title && Boolean(errors.title)}
          helperText={touched.title && errors.title}
          fullWidth
          placeholder="Insert news title here..."
          onChange={handleChange}
          onSave={isCreateMode ? undefined : handleSaveInfo}
        />

        <Grid container spacing={2} sx={{ paddingY: 4 }}>
          <Grid item xs={12} md={6}>
            <DatePicker
              value={dayjs(values.createdate)}
              label="Date"
              onChange={handleChangeDate}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Select
              label="Categorization"
              value={values.id_m_web_news_categorization}
              onChange={handleChangeCategory}
              options={categorizationOptions}
              width="100%"
              fullWidth
              disabled={!categorizationOptions?.length}
            />
          </Grid>
        </Grid>

        <TextInput
          name="link_media"
          label="Link to Media"
          value={values.link_media}
          error={touched.link_media && Boolean(errors.link_media)}
          helperText={touched.link_media && errors.link_media}
          fullWidth
          placeholder="Insert link to media here..."
          onChange={handleChange}
          onSave={isCreateMode ? undefined : handleSaveInfo}
        />

        <Box>
          <FileUploadInput
            label="News File"
            filename={localPreviewImg?.name ?? initialData.preview_image ?? ""}
            setFile={setLocalPreviewImg}
            disabled={!localPreviewImg}
            loading={isLoadingUpdateNewsFile}
            onSubmit={isCreateMode ? undefined : handleSaveNewsFile}
            acceptFileFormat="image/*"
          />
        </Box>

        <Box>
          <FileUploadInput
            label="Download File"
            filename={
              localDownloadFile?.name ?? initialData.download_file ?? ""
            }
            setFile={setLocalDownloadFile}
            disabled={!localDownloadFile}
            loading={isLoadingUpdateDownloadFile}
            onSubmit={isCreateMode ? undefined : handleSaveDownloadFile}
            acceptFileFormat="application/pdf"
          />
        </Box>

        {isCreateMode ? (
          <Box pt={4} textAlign="right">
            <Button
              variant="outlined"
              onClick={onClose}
              sx={{ marginRight: 2 }}
            >
              Cancel
            </Button>

            <Button variant="contained" type="submit">
              {isLoadingCreate ? (
                <Box className={classes.submitLoaderWrapper}>
                  <CircularProgress size={20} color="inherit" />
                </Box>
              ) : (
                "Create News"
              )}
            </Button>
          </Box>
        ) : null}
      </form>
    </Box>
  );
};

export default EditIndexLabForm;
