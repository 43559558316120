import { FC } from "react";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";

interface Props {
  direction: "asc" | "desc";
}

const SortArrow: FC<Props> = ({ direction }) => {
  return (
    <ArrowDropDownCircleIcon
      style={{
        width: "0.875rem",
        height: "0.875rem",
        fill: "#efefef",
        marginLeft: "0.75rem",
        transform: `rotate(${direction === "desc" ? "0" : "180"}deg)`,
      }}
    />
  );
};

export default SortArrow;
