import { FC, useMemo, useState } from "react";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseIcon from "@mui/icons-material/Close";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Box, StandardTextFieldProps, TextField } from "@mui/material";
import { useStyles } from "./styles";

interface Props extends StandardTextFieldProps {
  value: string;
  onSave?: (fieldName: string, value: string) => void;
  maxLength?: number;
}

const TextInput: FC<Props> = ({
  name,
  value,
  maxLength = 100,
  disabled: propDisabled,
  onSave,
  ...otherProps
}) => {
  const classes = useStyles();
  const [stateDisabled, setStateDisabled] = useState<boolean>(true);
  const disabled = propDisabled || (stateDisabled && !!onSave);

  const charsLeft = useMemo(
    () => maxLength - value.length,
    [maxLength, value.length]
  );

  const toggleDisabled = () => setStateDisabled(!stateDisabled);

  const handleSave = () => {
    toggleDisabled();
    if (onSave) {
      onSave(name ?? "", value);
    }
  };

  return (
    <Box className={classes.container}>
      <TextField
        className={classes.textField}
        variant="standard"
        name={name}
        InputLabelProps={{ shrink: true }}
        value={value}
        disabled={disabled}
        {...otherProps}
      />
      {propDisabled ? null : (
        <Box className={classes.extraContainer}>
          <Box className={classes.charsLeftContainer}>
            <Box className={classes.charsLeft}>{charsLeft}</Box>
            &nbsp;&nbsp;characters left (with spaces)
          </Box>
          {onSave && (
            <Box className={classes.actionsContainer}>
              <Box className={classes.actionIcon} onClick={toggleDisabled}>
                {stateDisabled ? (
                  <EditOutlinedIcon fontSize="small" sx={{ marginRight: 1 }} />
                ) : (
                  <CloseIcon fontSize="small" sx={{ marginRight: 1 }} />
                )}
              </Box>
              <Box className={classes.actionIcon} onClick={handleSave}>
                <CheckOutlinedIcon fontSize="small" />
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default TextInput;
