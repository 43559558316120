import { FC, useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
} from "@mui/material";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import * as yup from "yup";
import { useCreateGovernanceMutation, useUpdateGovernanceDownloadFileMutation, useUpdateGovernanceMutation } from "../../../redux/queries/governances";
import { Governance, CreateGovernancePayload } from "../../../types";
import FileUploadInput from "../../FileUploadInput";
import TextInput from "../../TextInput";
import { useStyles } from "../styles";

interface Props {
  data?: Governance;
  onClose: () => void;
}

const validationSchema = yup.object({
  title: yup.string().required("Index name is required"),
});

const EditGovernanceForm: FC<Props> = ({ data, onClose }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [updateInfo, { isError, isSuccess }] = useUpdateGovernanceMutation();
  const [
    updateDownloadFile,
    {
      isSuccess: isSuccessUpdateDownloadFile,
      isError: isErrorUpdateDownloadFile,
      isLoading: isLoadingUpdateDownloadFile,
    },
  ] = useUpdateGovernanceDownloadFileMutation();
  const [
    createGovernance,
    {
      isSuccess: isSuccessCreate,
      isLoading: isLoadingCreate,
      isError: isErrorCreate,
    },
  ] = useCreateGovernanceMutation();

  const isCreateMode = !data?.id_m_governance;

  const initialData = !isCreateMode
    ? data
    : ({
        id_m_governance: -1,
        title: "",
        file: "",
      } as CreateGovernancePayload);

  const [localDownloadFile, setLocalDownloadFile] = useState<File>();

  const { handleChange, handleSubmit, touched, errors, values } =
  useFormik<CreateGovernancePayload>({
    initialValues: initialData,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const formData = new FormData();
      formData.set("title", values.title);
      formData.set("file", localDownloadFile as any);
      if (isCreateMode) {
        createGovernance(formData);
      }
    },
    enableReinitialize: true,
  });

  const handleSaveInfo = (fieldName: string, value: string) => {
    updateInfo({
      id_m_governance: initialData?.id_m_governance,
      title : value,
    });
  };



  const handleSaveDownloadFile = () => {
    if (localDownloadFile) {
      updateDownloadFile({
        id_m_governance: initialData.id_m_governance,
        file: localDownloadFile,
      });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar({
        message: "Successfully updated news.",
        variant: "success",
        autoHideDuration: 3000,
      });
    }
  }, [enqueueSnackbar, isSuccess]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar({
        message: "Failed to update news.",
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }, [enqueueSnackbar, isError]);

  // useEffect(() => {
  //   if (isSuccessUpdateNewsFile) {
  //     enqueueSnackbar({
  //       message: "Successfully updated preview image.",
  //       variant: "success",
  //       autoHideDuration: 3000,
  //     });
  //     setLocalPreviewImg(undefined);
  //   }
  // }, [enqueueSnackbar, isSuccessUpdateNewsFile]);

  useEffect(() => {
    if (isSuccessUpdateDownloadFile) {
      enqueueSnackbar({
        message: "Successfully updated download file.",
        variant: "success",
        autoHideDuration: 3000,
      });
      setLocalDownloadFile(undefined);
    }
  }, [enqueueSnackbar, isSuccessUpdateDownloadFile]);

  useEffect(() => {
    if (isErrorUpdateDownloadFile) {
      enqueueSnackbar({
        message: "Failed to update download file.",
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }, [enqueueSnackbar, isErrorUpdateDownloadFile]);

  useEffect(() => {
    if (isSuccessCreate) {
      enqueueSnackbar({
        message: "Successfully created news.",
        variant: "success",
        autoHideDuration: 3000,
      });
      onClose();
    }
  }, [enqueueSnackbar, isSuccessCreate, onClose]);

  useEffect(() => {
    if (isErrorCreate) {
      enqueueSnackbar({
        message: "Failed to create news.",
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }, [enqueueSnackbar, isErrorCreate]);

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <TextInput
          name="title"
          label="Title"
          value={values.title}
          error={touched.title && Boolean(errors.title)}
          helperText={touched.title && errors.title}
          fullWidth
          placeholder="Insert news title here..."
          onChange={handleChange}
          onSave={isCreateMode ? undefined : handleSaveInfo}
        />

        <Box>
          <FileUploadInput
            label="Pdf File"
            filename={
              localDownloadFile?.name ?? initialData.file ?? ""
            }
            setFile={setLocalDownloadFile}
            disabled={!localDownloadFile}
            loading={isLoadingUpdateDownloadFile}
            onSubmit={isCreateMode ? undefined : handleSaveDownloadFile}
            acceptFileFormat="application/pdf"
          />
        </Box>

        {isCreateMode ? (
          <Box pt={4} textAlign="right">
            <Button
              variant="outlined"
              onClick={onClose}
              sx={{ marginRight: 2 }}
            >
              Cancel
            </Button>

            <Button variant="contained" type="submit">
              {isLoadingCreate ? (
                <Box className={classes.submitLoaderWrapper}>
                  <CircularProgress size={20} color="inherit" />
                </Box>
              ) : (
                "Update"
              )}
            </Button>
          </Box>
        ) : null}
      </form>
    </Box>
  );
};

export default EditGovernanceForm;
