import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: "1.5rem",
    background: "#fff",
    borderRadius: "0.25rem",
    overflow: "hidden",
  },
  title: {
    width: "100%",
    color: "#88B5FF",
    fontSize: 18,
    fontWeight: 600,
    textTransform: "uppercase",
    paddingBottom: "0.75rem",
    borderBottom: "2px solid #88B5FF",
  },
}));
