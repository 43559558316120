import { useEffect } from "react";
import { Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import ImgBackground from "../../assets/images/login_background.png";
import LoginForm from "../../components/Forms/LoginForm";
import { useQuery } from "../../hooks/useQuery";
import { useAppSelector } from "../../hooks/useStore";
import { useLoginMutation } from "../../redux/queries/auth";
import { selectToken, selectUserId } from "../../redux/slices/auth";
import { useStyles } from "./styles";

const LoginPage = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const userId = useAppSelector(selectUserId);
  const token = useAppSelector(selectToken);
  const query = useQuery();

  const [login, { isError, isSuccess, isLoading }] = useLoginMutation();

  const onLogin = (email: string, password: string) => {
    login({
      user: email,
      password,
    });
  };

  useEffect(() => {
    if (userId && token) {
      navigate(`/${query.get("from") || ""}`);
    }
  }, [navigate, query, token, userId]);

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar({
        message: `Welcome, ${userId}!`,
        variant: "info",
      });
    }
  }, [enqueueSnackbar, isSuccess, userId]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar({
        message: "Authentication failed, please try again.",
        variant: "error",
      });
    }
  }, [enqueueSnackbar, isError]);

  return (
    <Box
      className={classes.root}
      sx={{
        background: `url(${ImgBackground})`,
      }}
    >
      <LoginForm onLogin={onLogin} isLoading={isLoading} />
    </Box>
  );
};

export default LoginPage;
