import { createApi } from "@reduxjs/toolkit/query/react";
import { ApiResponseData, WebStatus } from "../../types";
import { Cryptocurrency } from "../../types/cryptocurrencies";
import { getTags } from "../../utils/rtk-query";
import { baseQuery } from "./base";

const reducerPath = "cryptocurrencies";

export const cryptocurrenciesApi = createApi({
  reducerPath,
  baseQuery: baseQuery,
  tagTypes: [reducerPath],
  endpoints: (builder) => ({
    fetchCryptocurrencies: builder.query({
      query: () => "/v2/crypto/cms?limit=9999",
      transformResponse: (responseData: ApiResponseData<Cryptocurrency[]>) =>
        responseData?.data || [],
      providesTags: (result) => getTags(reducerPath, "ticker", result),
    }),
    fetchCryptocurrencyDetails: builder.query({
      query: (ticker: string) => `/v2/crypto/${ticker}`,
      transformResponse: (responseData: ApiResponseData<Cryptocurrency>) =>
        responseData?.data || [],
    }),
    updateCryptocurrencyInfo: builder.mutation({
      query: ({
        ticker,
        ...data
      }: {
        ticker: string;
        status?: WebStatus;
        name?: string;
        description?: string;
      }) => ({
        url: `/v2/crypto/${ticker}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: reducerPath, id: arg.ticker },
      ],
    }),
    updateCryptocurrencyLogo: builder.mutation({
      query: ({
        ticker,
        formData,
      }: {
        ticker: string;
        formData: FormData;
      }) => ({
        url: `/v2/crypto/${ticker}/image`,
        method: "PUT",
        body: formData,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: reducerPath, id: arg.ticker },
      ],
    }),
    updateCryptocurrencyBackground: builder.mutation({
      query: ({
        ticker,
        formData,
      }: {
        ticker: string;
        formData: FormData;
      }) => ({
        url: `/v2/crypto/${ticker}/background`,
        method: "PUT",
        body: formData,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: reducerPath, id: arg.ticker },
      ],
    }),
  }),
});

export const {
  useFetchCryptocurrenciesQuery,
  useFetchCryptocurrencyDetailsQuery,
  useUpdateCryptocurrencyInfoMutation,
  useUpdateCryptocurrencyLogoMutation,
  useUpdateCryptocurrencyBackgroundMutation,
} = cryptocurrenciesApi;
