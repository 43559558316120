import { createApi } from "@reduxjs/toolkit/query/react";
import { ValidationData } from "../../types";
import { baseQuery } from "./base";

const reducerPath = "auth-api";

export const authApi = createApi({
  reducerPath,
  baseQuery: baseQuery,
  tagTypes: [reducerPath],
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data: { user: string; password: string }) => ({
        url: `/v2/login`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [reducerPath],
    }),
    validate: builder.query({
      query: () => "/v1/validate",
      transformResponse: (responseData: ValidationData) => responseData,
    }),
  }),
});

export const { useLoginMutation, useLazyValidateQuery } = authApi;
