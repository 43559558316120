import { FC } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Drawer } from "@mui/material";
import { useIsXlDesktopView } from "../../hooks/useWindowSize";
import { News, NewsCategorization } from "../../types";
import Section from "../Section";
import EditNewsForm from "./EditNewsForm";
import { useStyles } from "./styles";

interface Props {
  data?: News;
  categorizations?: NewsCategorization[];
  onClose: () => void;
}

const EditNewsPanel: FC<Props> = ({ data, categorizations, onClose }) => {
  const classes = useStyles();
  const inXlDesktopView = useIsXlDesktopView();

  const sectionTitle = data?.id_m_web_news
    ? "Index "
    : "New Index Lab Information";

  if (inXlDesktopView) {
    return (
      <Box className={classes.editBox} pl={2}>
        <Box className={classes.closeContainer}>
          <Box className={classes.closeIcon} onClick={onClose}>
            <CloseIcon />
          </Box>
        </Box>
        <Section title={sectionTitle}>
          <EditNewsForm
            data={data}
            categorizations={categorizations}
            onClose={onClose}
          />
        </Section>
      </Box>
    );
  }

  return (
    <Drawer anchor="right" open onClose={onClose}>
      <Box p={2}>
        <EditNewsForm
          data={data}
          categorizations={categorizations}
          onClose={onClose}
        />
      </Box>
    </Drawer>
  );
};

export default EditNewsPanel;
