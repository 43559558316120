import { FC, useEffect, useState } from "react";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseIcon from "@mui/icons-material/Close";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Box, SelectChangeEvent } from "@mui/material";
import { useSnackbar } from "notistack";
import { useUpdateIndexStatusMutation } from "../../../../redux/queries/indexes";
import { IndexListItem, WebStatus } from "../../../../types";
import { STATUS_OPTIONS } from "../../../../utils/constants";
import Select from "../../../Select";
import { useStyles } from "../../styles";

interface Props {
  data: IndexListItem;
}

const StatusSelect: FC<Props> = ({ data }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [updateIndexStatus, { isError }] = useUpdateIndexStatusMutation();

  const [status, setStatus] = useState<WebStatus>(data.web_status);
  const [stateDisabled, setStateDisabled] = useState<boolean>(true);

  const toggleDisabled = () => setStateDisabled(!stateDisabled);
  const onSave = () => {
    if (!stateDisabled) {
      updateIndexStatus({
        index_id: data.index_id,
        status,
      });
      setStateDisabled(true);
    }
  };

  const handleChangeStatus = (event: SelectChangeEvent<any>) => {
    setStatus(event.target.value ?? null);
  };

  useEffect(() => {
    setStatus(data.web_status);
  }, [data.web_status]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar({
        message: "Failed to update index status.",
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }, [enqueueSnackbar, isError]);

  return (
    <Box>
      <Box className={classes.primaryLabel} mb={2}>
        Status
      </Box>
      <Box className={classes.selectorsRow}>
        <Select
          label="Status"
          value={status ?? ""}
          onChange={handleChangeStatus}
          options={STATUS_OPTIONS}
          width={200}
          disabled={stateDisabled}
        />
      </Box>
      <Box className={classes.actionsContainer}>
        <Box className={classes.actionIcon} onClick={toggleDisabled}>
          {stateDisabled ? (
            <EditOutlinedIcon fontSize="small" sx={{ marginRight: 1 }} />
          ) : (
            <CloseIcon fontSize="small" sx={{ marginRight: 1 }} />
          )}
        </Box>
        <Box className={classes.actionIcon} onClick={onSave}>
          <CheckOutlinedIcon fontSize="small" />
        </Box>
      </Box>
    </Box>
  );
};

export default StatusSelect;
