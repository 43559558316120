import { createApi } from "@reduxjs/toolkit/query/react";
import {
  ApiResponseData,
  WebStatus,
  News,
  NewsCategorization,
} from "../../types";
import { RTK_SLICE_API_LIST } from "../../utils/constants";
import { convertObjToFormData, getTags } from "../../utils/rtk-query";
import { baseQuery } from "./base";

const reducerPath = "news";

export const newsApi = createApi({
  reducerPath,
  baseQuery: baseQuery,
  tagTypes: [reducerPath],
  endpoints: (builder) => ({
    fetchNews: builder.query({
      query: () => "/v2/news",
      transformResponse: (responseData: ApiResponseData<News[]>) =>
        responseData?.data || [],
      providesTags: (result) => getTags(reducerPath, "id_m_web_news", result),
    }),
    fetchNewsCategorization: builder.query({
      query: () => "/v2/news/categorization",
      transformResponse: (
        responseData: ApiResponseData<NewsCategorization[]>
      ) => responseData?.data || [],
    }),
    updateNews: builder.mutation({
      query: ({
        id_m_web_news,
        ...data
      }: {
        id_m_web_news: number;
        title?: string;
        link_media?: string;
        categorization_id?: number;
        status?: WebStatus;
        createdate?: string;
      }) => ({
        url: `/v2/news/${id_m_web_news}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: reducerPath, id: arg.id_m_web_news },
      ],
    }),
    updateNewsFile: builder.mutation({
      query: ({
        id_m_web_news,
        file,
      }: {
        id_m_web_news: number;
        file: File;
      }) => ({
        url: `/v2/news/${id_m_web_news}/preview-image`,
        method: "PUT",
        body: convertObjToFormData({ file }),
      }),
      invalidatesTags: (result, error, arg) => [
        { type: reducerPath, id: arg.id_m_web_news },
      ],
    }),
    updateDownloadFile: builder.mutation({
      query: ({
        id_m_web_news,
        file,
      }: {
        id_m_web_news: number;
        file: File;
      }) => ({
        url: `/v2/news/${id_m_web_news}/download-file`,
        method: "PUT",
        body: convertObjToFormData({ file }),
      }),
      invalidatesTags: (result, error, arg) => [
        { type: reducerPath, id: arg.id_m_web_news },
      ],
    }),
    createNews: builder.mutation({
      query: (data: {
        title: string;
        link_media: string;
        categorization_id: string;
        preview_image?: File;
        download_file?: File;
      }) => ({
        url: `/v2/news`,
        method: "POST",
        body: convertObjToFormData(data),
      }),
      invalidatesTags: () => [{ type: reducerPath, id: RTK_SLICE_API_LIST }],
    }),
  }),
});

export const {
  useFetchNewsCategorizationQuery,
  useFetchNewsQuery,
  useUpdateNewsFileMutation,
  useUpdateNewsMutation,
  useUpdateDownloadFileMutation,
  useCreateNewsMutation,
} = newsApi;
