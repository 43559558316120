import { FC, ReactNode, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks/useStore";
import { useLazyValidateQuery } from "../redux/queries/auth";
import { logout, selectToken, selectUserId } from "../redux/slices/auth";

interface Props {
  children: ReactNode;
}

const ProtectedRoute: FC<Props> = ({ children }) => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const userId = useAppSelector(selectUserId);
  const token = useAppSelector(selectToken);

  const [trigger, { data, error }] = useLazyValidateQuery();

  useEffect(() => {
    if (token) {
      trigger({});
    }
  }, [token, trigger]);

  useEffect(() => {
    if (error) {
      dispatch(logout());
    }
  }, [data, dispatch, error]);

  return userId && token ? (
    <>{children}</>
  ) : (
    <Navigate to="/auth/login" state={{ from: location }} replace />
  );
};

export default ProtectedRoute;
