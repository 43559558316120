import { FC } from "react";
import { DatePickerProps } from "@mui/x-date-pickers";
import { DatePicker as OrignialDatePicker } from "@mui/x-date-pickers/DatePicker";

interface Props<T> extends DatePickerProps<T> {}

const DatePicker: FC<Props<any>> = ({ ...props }) => {
  return <OrignialDatePicker {...props} />;
};

export default DatePicker;
