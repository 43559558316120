import { IndexFileType } from "../types";

export const RTK_SLICE_API_LIST = "LIST";

export const INDEX_FILE_TYPES = [
  "factsheet",
  "benchmark_statement",
  "methodology_guidebook",
  "corporate_actions",
  "constituents_list",
] as const;

export const EXCLUDED_FILE_TYPES: IndexFileType[] = ["constituents_list"];

export const INCLUDED_FILE_TYPES: IndexFileType[] = INDEX_FILE_TYPES.filter(
  (ift) => !EXCLUDED_FILE_TYPES.find((eft) => eft === ift)
);

export const STATUS_OPTIONS = [
  {
    label: "Active",
    value: "A",
  },
  {
    label: "Inactive",
    value: "I",
  },
  {
    label: "Hidden",
    value: "H",
  },
  {
    label: "Test",
    value: "T",
  },
  {
    label: "N/A",
    value: "",
  },
];
