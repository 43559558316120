import { createApi } from "@reduxjs/toolkit/query/react";
import {
  ApiResponseData,
  AssetClass,
  FamiliesIndexes,
  IndexListItem,
  IndexWithWeight,
  IndexType,
  WebStatus,
  Status,
} from "../../types";
import { getTags } from "../../utils/rtk-query";
import { baseQuery } from "./base";

const reducerPath = "indexes";
const weightPath = "indexes-weight";
const assetClassPath = "indexes-assetclass";
const indexTypePath = "indexes-indextype";

export const indexesApi = createApi({
  reducerPath,
  baseQuery: baseQuery,
  tagTypes: [reducerPath],
  endpoints: (builder) => ({
    fetchIndexList: builder.query({
      query: () => "/v2/index/list",
      transformResponse: (responseData: ApiResponseData<IndexListItem[]>) =>
        responseData?.data || [],
      providesTags: (result) => getTags(reducerPath, "index_id", result),
    }),
    fetchIndexesWithWeight: builder.query({
      query: () => "/v1/indexes_with_weight",
      transformResponse: (responseData: ApiResponseData<IndexWithWeight[]>) =>
        responseData?.data || [],
      providesTags: (result) => getTags(weightPath, "index_id", result),
    }),
    fetchFamiliesIndexes: builder.query({
      query: () => "/v1/families_indexes",
      transformResponse: (responseData: ApiResponseData<FamiliesIndexes>) =>
        responseData?.data ?? [],
      // providesTags: (result) => getTags(familyPath, "id", result),
    }),
    fetchAssetClasses: builder.query({
      query: () => "/v2/index/asset-class",
      transformResponse: (responseData: ApiResponseData<AssetClass[]>) =>
        responseData?.data ?? [],
      providesTags: (result) => getTags(assetClassPath, "id", result),
    }),
    fetchIndexTypes: builder.query({
      query: () => "/v2/index/index-type",
      transformResponse: (responseData: ApiResponseData<IndexType[]>) =>
        responseData?.data ?? [],
      providesTags: (result) => getTags(indexTypePath, "id", result),
    }),
    updateIndexInformation: builder.mutation({
      query: ({
        index_id,
        ...data
      }: {
        index_id: string;
        description?: string;
        name?: string;
      }) => ({
        url: `/v2/index/${index_id}/information`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: reducerPath, id: arg.index_id },
      ],
    }),
    updateIndexCategory: builder.mutation({
      query: ({
        index_id,
        ...data
      }: {
        index_id: string;
        asset_class: number;
        index_type: number;
      }) => ({
        url: `/v2/index/category?index_id=${index_id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: reducerPath, id: arg.index_id },
      ],
    }),
    updateIndexStatus: builder.mutation({
      query: ({
        index_id,
        ...data
      }: {
        index_id: string;
        status: WebStatus;
      }) => ({
        url: `/v2/index/${index_id}/status`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: reducerPath, id: arg.index_id },
      ],
    }),
    updateTop10ConstituentsStatus: builder.mutation({
      query: ({
        index_id,
        ...data
      }: {
        index_id: string;
        top_10_constituents_status?: Status;
        constituents_file_status?: Status;
        custom_button_status?: Status;
        file_should_be_downloadable?: Status;
        custom_button_name?: string;
        custom_button_url?: string;     
      }) => ({
        url: `/v2/index/${index_id}/update-top-10-constituents-status`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: reducerPath, id: arg.index_id },
      ],
    }),
  }),
});

export const {
  useFetchIndexListQuery,
  useFetchIndexesWithWeightQuery,
  useFetchFamiliesIndexesQuery,
  useFetchAssetClassesQuery,
  useFetchIndexTypesQuery,
  useUpdateIndexInformationMutation,
  useUpdateIndexCategoryMutation,
  useUpdateIndexStatusMutation,
  useUpdateTop10ConstituentsStatusMutation,
} = indexesApi;
