import { FC, ReactNode } from "react";
import { Box } from "@mui/material";
import { useStyles } from "./styles";

interface Props {
  title: string;
  children: ReactNode;
}

const Section: FC<Props> = ({ title, children }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.title}>{title}</Box>
      <Box>{children}</Box>
    </Box>
  );
};

export default Section;
