import { useState, ChangeEvent, useMemo } from "react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Box, Switch } from "@mui/material";
import EditCryptocurrencyPanel from "../../components/EditCryptocurrencyPanel";
import SearchInput from "../../components/SearchInput";
import Section from "../../components/Section";
import StatusBadge from "../../components/StatusBadge";
import Table, { Column } from "../../components/Table";
import {
  useFetchCryptocurrenciesQuery,
  useUpdateCryptocurrencyInfoMutation,
} from "../../redux/queries/cryptocurrencies";
import { Cryptocurrency } from "../../types/cryptocurrencies";
import { useStyles } from "./styles";

const CryptocurrenciesPage = () => {
  const classes = useStyles();

  const { data, isFetching } = useFetchCryptocurrenciesQuery({});
  const [updateCryptocurrencyInfo] = useUpdateCryptocurrencyInfoMutation();

  const [query, setQuery] = useState<string>("");
  const [page, setPage] = useState<number>(-1);
  const [selected, setSelected] = useState<string>();

  const handleChangeQuery = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  const handleChangeStatus = (
    e: ChangeEvent<HTMLInputElement>,
    data: Cryptocurrency
  ) => {
    updateCryptocurrencyInfo({
      ticker: data.ticker,
      status: data.status === "A" ? "I" : "A",
    });
  };

  const TableColumns: Column[] = [
    {
      key: "name",
      label: "Name",
      sortable: true,
      colSpan: 3,
      renderCell: (data) => (
        <Box sx={{ color: "primary.main", fontWeight: "bold" }}>
          {data.name}
        </Box>
      ),
    },
    {
      key: "ticker",
      label: "Ticker",
      sortable: true,
      renderCell: (data) => <Box>{data.ticker}</Box>,
    },
    {
      key: "web_status",
      label: "Status",
      sortable: true,
      renderCell: (data) => <StatusBadge status={data.status} />,
    },
    {
      key: "option",
      label: "Options",
      sortable: false,
      renderCell: (data) => (
        <Box
          sx={{ cursor: "pointer" }}
          onClick={() => setSelected(data.ticker)}
        >
          <EditOutlinedIcon />
        </Box>
      ),
    },
    {
      key: "activation",
      label: "Activation",
      sortable: false,
      renderCell: (data) => (
        <Switch
          checked={data.status === "A"}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleChangeStatus(e, data as Cryptocurrency)
          }
        />
      ),
    },
  ];

  const selectedItem = useMemo(
    () => data?.find((item) => item.ticker === selected),
    [data, selected]
  );

  const visibleKeys = TableColumns.map((col) => col.key);

  // Filter table rows by "search" input value
  const filteredTableData = useMemo(() => {
    if (!data) return [];
    if (query?.length < 3) return data;
    return data.filter((item) => {
      return visibleKeys.some(
        (k) =>
          (item as any)[k] &&
          ((item as any)[k] as any)
            .toString()
            .toLowerCase()
            .includes(query.toLowerCase())
      );
    });
  }, [query, data, visibleKeys]);

  return (
    <Box className={classes.page}>
      <Box className={classes.pageHeader}>Cryptocurrencies</Box>
      <Box className={classes.container}>
        <Box className={classes.tableBox}>
          <Section title="Cryptocurrency list">
            <Box className={classes.headerSection}>
              <Box className={classes.selectorsRow}>
                <Box className={classes.searchContainer}>
                  <SearchInput
                    label=""
                    placeholder="Search by..."
                    onChange={handleChangeQuery}
                  />
                </Box>
              </Box>
            </Box>
            <Box>
              <Table
                columns={TableColumns}
                data={filteredTableData}
                currentPage={page}
                setCurrentPage={setPage}
                loading={isFetching}
              />
            </Box>
          </Section>
        </Box>
        {selectedItem ? (
          <EditCryptocurrencyPanel
            data={selectedItem}
            onClose={() => setSelected(undefined)}
          />
        ) : null}
      </Box>
    </Box>
  );
};

export default CryptocurrenciesPage;
