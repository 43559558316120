import { FC } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Drawer } from "@mui/material";
import { useIsXlDesktopView } from "../../hooks/useWindowSize";
import { Announcement, AnnouncementCategory } from "../../types";
import Section from "../Section";
import EditAnnouncementForm from "./EditAnnouncementForm";
import { useStyles } from "./styles";

interface Props {
  data?: Announcement;
  categories?: AnnouncementCategory[];
  onClose: () => void;
}

const EditAnnouncementPanel: FC<Props> = ({ data, categories, onClose }) => {
  const classes = useStyles();
  const inXlDesktopView = useIsXlDesktopView();

  if (inXlDesktopView) {
    return (
      <Box className={classes.editBox} pl={2}>
        <Box className={classes.closeContainer}>
          <Box className={classes.closeIcon} onClick={onClose}>
            <CloseIcon />
          </Box>
        </Box>
        <Section
          title={
            data?.id_m_web_announcement
              ? "Announcement Details"
              : "New Announcement Details"
          }
        >
          <EditAnnouncementForm
            data={data}
            categories={categories}
            onClose={onClose}
          />
        </Section>
      </Box>
    );
  }

  return (
    <Drawer anchor="right" open onClose={onClose}>
      <Box className={classes.editBox} p={2}>
        <Section
          title={
            data?.id_m_web_announcement
              ? "Announcement Details"
              : "New Announcement Details"
          }
        >
          <EditAnnouncementForm
            data={data}
            categories={categories}
            onClose={onClose}
          />
        </Section>
      </Box>
    </Drawer>
  );
};

export default EditAnnouncementPanel;
