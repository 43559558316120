import { ChangeEventHandler, FC } from "react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { FormControl, Input, InputAdornment, InputLabel } from "@mui/material";

interface Props {
  label: string;
  placeholder?: string;
  variant?: "standard" | "outlined" | "filled";
  onChange: ChangeEventHandler<HTMLInputElement>;
}

const SearchInput: FC<Props> = ({
  label,
  variant = "standard",
  placeholder,
  onChange,
}) => {
  return (
    <FormControl variant={variant} sx={{ minWidth: 240, paddingBottom: 2 }}>
      <InputLabel>{label}</InputLabel>
      <Input
        startAdornment={
          <InputAdornment position="start">
            <SearchOutlinedIcon />
          </InputAdornment>
        }
        placeholder={placeholder}
        onChange={onChange}
      />
    </FormControl>
  );
};

export default SearchInput;
