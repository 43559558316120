import { FC, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  SelectChangeEvent,
} from "@mui/material";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import * as yup from "yup";
import { useCreateAnnouncementMutation } from "../../../redux/queries/announcements";
import {
  Announcement,
  AnnouncementCategory,
  CreateAnnouncementPayload,
} from "../../../types";
import DatePicker from "../../DatePicker";
import FileUploadInput from "../../FileUploadInput";
import Select from "../../Select";
import TextInput from "../../TextInput";
import { useStyles } from "../styles";

interface Props {
  data?: Announcement;
  categories?: AnnouncementCategory[];
  onClose: () => void;
}

const validationSchema = yup.object({
  headline: yup.string().required("Headline is required"),
  date: yup.string().required("Date is required"),
  category_id: yup.number().required("Category is required"),
});

const EditAnnouncementForm: FC<Props> = ({ data, categories, onClose }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [createAnnouncement, { isError, isSuccess, isLoading }] =
    useCreateAnnouncementMutation();

  const initialData = data?.id_m_web_announcement
    ? {
        date: data.date,
        headline: data.headline,
        category_id: data.id_m_category_announcement.toString(),
        file: data.file,
      }
    : ({
        date: DateTime.local().startOf("day").toISO(),
        file: undefined,
        headline: "",
        category_id: "",
      } as CreateAnnouncementPayload);

  const isCreateMode = !data?.id_m_web_announcement;

  const [localFile, setLocalFile] = useState<File>();
  const [isMissingLocalFile, setIsMissingLocalFile] = useState<boolean>(false);

  const { handleChange, handleSubmit, setFieldValue, touched, errors, values } =
    useFormik<CreateAnnouncementPayload>({
      initialValues: initialData,
      validationSchema: validationSchema,
      onSubmit: (values) => {
        const formData = new FormData();
        formData.set("headline", values.headline);
        formData.set("category_id", values.category_id.toString());
        formData.set(
          "date",
          DateTime.fromISO(values.date).toFormat("yyyy-LL-dd")
        );
        formData.set("file", localFile as any);

        if (isCreateMode) {
          createAnnouncement(formData);
        }
      },
      enableReinitialize: true,
    });
  const handleChangeCategory = (e: SelectChangeEvent<any>) => {
    setFieldValue("category_id", e.target.value);
  };

  const handleChangeDate = (e: any) => {
    setFieldValue("date", e.$d.toISOString());
  };

  const categoryOptions = useMemo(() => {
    if (!categories) return [];
    return categories.map(({ name, id_m_category_announcement }) => ({
      label: name,
      value: id_m_category_announcement,
    }));
  }, [categories]);

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar({
        message: "Successfully created a new announcement.",
        variant: "success",
        autoHideDuration: 3000,
      });
      onClose();
    }
  }, [enqueueSnackbar, isSuccess, onClose]);

  useEffect(() => {
    if (isError) {
      enqueueSnackbar({
        message: "Failed to create new announcement.",
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }, [enqueueSnackbar, isError]);

  useEffect(() => {
    if (localFile) {
      setIsMissingLocalFile(false);
    }
  }, [localFile]);

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <TextInput
          name="headline"
          label="Headline"
          value={values.headline}
          error={touched.headline && Boolean(errors.headline)}
          helperText={touched.headline && errors.headline}
          fullWidth
          placeholder="Type headline..."
          onChange={handleChange}
        />
        <Grid container spacing={2} sx={{ paddingY: 4 }}>
          <Grid item xs={12} md={6}>
            <DatePicker
              value={dayjs(values.date)}
              label="Date"
              onChange={handleChangeDate}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Select
              label="Categorization"
              value={values.category_id}
              error={touched.category_id && Boolean(errors.category_id)}
              noPaddingBottom={true}
              onChange={handleChangeCategory}
              options={categoryOptions}
              width="100%"
              fullWidth
              disabled={!categoryOptions?.length}
              id="form__categorization"
            />
            {touched.category_id && Boolean(errors.category_id) && (
              <label
                htmlFor="#form__categorization"
                className={classes.errorLabel}
              >
                Categorization value is required
              </label>
            )}
          </Grid>
        </Grid>
        <Box>
          <FileUploadInput
            label="PDF File"
            filename={localFile?.name ?? initialData.file}
            setFile={setLocalFile}
            disabled={!localFile}
            error={isMissingLocalFile}
            loading={false}
            acceptFileFormat="application/pdf"
          />
        </Box>
        <Box pt={4} textAlign="right">
          <Button variant="outlined" onClick={onClose} sx={{ marginRight: 2 }}>
            Cancel
          </Button>

          <Button
            variant="contained"
            type="submit"
            onClick={() =>
              localFile
                ? setIsMissingLocalFile(false)
                : setIsMissingLocalFile(true)
            }
          >
            {isLoading ? (
              <Box className={classes.submitLoaderWrapper}>
                <CircularProgress size={20} color="inherit" />
              </Box>
            ) : isCreateMode ? (
              " Create Announcement"
            ) : (
              "Update Details"
            )}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default EditAnnouncementForm;
