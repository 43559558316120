import { AnyAction, combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { announcementsApi } from "./queries/announcements";
import { authApi } from "./queries/auth";
import { cryptocurrenciesApi } from "./queries/cryptocurrencies";
import { governancesApi } from "./queries/governances";
import { indexesApi } from "./queries/indexes";
import { indexFilesApi } from "./queries/indexFiles";
import { indexLabApi } from "./queries/indexLab";
import { newsApi } from "./queries/news";
import auth, { STORE_RESET } from "./slices/auth";

const persistConfig = {
  key: "root",
  storage,
};

const reducer = combineReducers({
  auth,
  [authApi.reducerPath]: authApi.reducer,
  [indexesApi.reducerPath]: indexesApi.reducer,
  [indexFilesApi.reducerPath]: indexFilesApi.reducer,
  [announcementsApi.reducerPath]: announcementsApi.reducer,
  [cryptocurrenciesApi.reducerPath]: cryptocurrenciesApi.reducer,
  [indexLabApi.reducerPath]: indexLabApi.reducer,
  [newsApi.reducerPath]: newsApi.reducer,
  [governancesApi.reducerPath]: governancesApi.reducer,
});

const resettableRootReducer = (
  state: ReturnType<typeof reducer> | undefined,
  action: AnyAction
) => {
  if (action.type === STORE_RESET) {
    return reducer(undefined, action);
  }
  return reducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, resettableRootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([
      authApi.middleware,
      indexesApi.middleware,
      indexFilesApi.middleware,
      announcementsApi.middleware,
      cryptocurrenciesApi.middleware,
      indexLabApi.middleware,
      newsApi.middleware,
      governancesApi.middleware,
    ]),
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store, null, () => {});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
